import ThemeMaker from "../../components/thememaker";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import requesticon from "../../icons/request.png";
import producticon from "../../icons/product.png";
import sellericon from "../../icons/sellerDetailsss.png";
import ordericon from "../../icons/order.png";
import axios from "axios";
import styles from "./dashboard.module.css";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
import { LineChart } from "@mui/x-charts/LineChart";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AgChartsReact } from "ag-charts-react";

const { apiEndpoint } = config;
var $ = require("jquery");

export default function Dashboard() {
  const [Dashboard, setDashboard] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const decode = tokenDecode();
  const { data } = decode;
  const id = data?.user?.seller_id;
  const [sidebarShow, setsidebarShow] = useState(checkstatus());
  const [pData, setpData] = useState([5, 10, 15, 20, 25, 30, 35]);
  const dateData = new Date(); 
  const year = dateData.getFullYear();
  const month = String(dateData.getMonth() + 1).padStart(2, "0"); 
  const date = String(dateData.getDate()).padStart(2, "0"); 

  const dateformat = `${year}-${month}-${date}`;
  const currentDate = new Date(dateformat); 

  const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const fifteenDaysAgo = new Date(currentDate.getTime() - 15 * 24 * 60 * 60 * 1000);
  const thirtyDaysAgo = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);

  const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];
  const formattedfifteenDaysAgo = fifteenDaysAgo.toISOString().split("T")[0];
  const formattedthirtyDaysAgo = thirtyDaysAgo.toISOString().split("T")[0];

  const [hasOrder, setHasorders] = useState(0);
  const [xLabels, setxLables]: any = useState([]);
  const [request, setrequest]: any = useState([]);
  const [shopifyrequest, setshopifyrequest]: any = useState([]);
  const [filterData, setFilterdata] = useState({
    start_date: formattedSevenDaysAgo,
    end_date: dateformat,
  });

  const [ReqResfilterDaata, setReqResfilterDaata] = useState({
    seller_id: "",
    endDate: "",
  });
  const [ShopifyfilterData, setShopifyFilterdata] = useState({
    start_date: formattedSevenDaysAgo,
    end_date: dateformat,
  });
  // useEffect(() => {
  //   ShopifyCountListing();
  // }, []);
  const [reqresLoading, setreqresLoading] = useState(false);
  const [reqresShopifyLoading, setreqresShopifyLoading] = useState(false);
  function checkUser() {
    if (id > 1) {
      return id;
    }
    if (id < 1) {
      return id;
    }
    if (id == 1) {
      return "";
    }
  }
  const [selectedProviderId, setSelectedProviderId] = useState(checkUser());
  const [PieSelectedProviderId, setPieSelectedProviderId] = useState(
    checkUser()
  );
  const [sellerList, setSellerList] = useState([]);
  const [isnoOrders, setisnoOrders] = useState(false);
  const [pieChartData, setpieChartData] = useState({
    cancelled: 0,
    accepted: 0,
    in_progress: 0,
    completed: 0,
  });
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
  const [ShopifyshipmentModalOpen, setShopifyshipmentModalOpen] =
    useState(false);
  const [allDashboardData, setallDashboardData] = useState<any>();
  const Sstyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
  };
  const [chartOptions, setChartOptions] = useState<any>({
    data: [
      { order_date: "12", total_orders: 14 },
      { order_date: "13", total_orders: 20 },
      { order_date: "14", total_orders: 30 },
      { order_date: "15", total_orders: 7 },
      { order_date: "15", total_orders: 7 },
      { order_date: "15", total_orders: 7 },
    ],
    series: [{ type: "bar", xKey: "order_date", yKey: "total_orders" }],
    background: {
      fill: "white",
    },
    title: { text: "Total Order in 20 Days" },
  });
  function checkstatus() {
    const token: any = localStorage.getItem("us_st_d");
    const tokenDecode: any = jwtDecode(token);
    return tokenDecode?.data?.user?.approve_status;
  }
  const logouthandler = () => {
    localStorage.removeItem("us_st_d");
    history.push("/");
  };
  function handleShipementModalClose() {
    setshipmentModalOpen(false);
  }
  function handleShopifyShipementModalClose() {
    setShopifyshipmentModalOpen(false);
  }
  const role_id = data?.user?.role_id;
  const user_id = data?.user?.id;
  const dat = useSelector((state: any) => state.loginReducer);
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;
  const dashboardListing = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/dashboard/total_countdata`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
        },
      });
      if (data.status === 200) {
        setallDashboardData(data.data);
        setHasorders(data?.data?.total_order);
        setLoading(false);
        setDashboard(Object.values(data.data));
      }
      if (data.status == "Token is Expired") {
        logouthandler();
      }
    } catch (error) {}
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const chartWidth = isMobile ? 260 : 500; // Adjust the width for mobile view
  const chartHeight = isMobile ? 260 : 300; // Adjust the height for mobile view
  useEffect(() => {
    dashboardListing();
    fetchSellerList();
    getPieChartData();
  }, []);
  useEffect(() => {
    getGraphData();
  }, [selectedProviderId]);
  useEffect(() => {
    getPieChartData();
  }, [PieSelectedProviderId]);
  const fetchSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/getsellerlist`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });

      if (data.status === 200) {
        setSellerList(data.data);
        // console.log("Data", data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  let paths: any;
  if (id == 1) {
    paths = [
      { path: "/productlist" },
      { path: "/sellerlist" },
      { path: "/orderlist" },
      { path: "/nonapprovedproducts" },
    ];
  } else {
    paths = [
      { path: "/catalog" },
      { path: "/userlist" },
      { path: "/customerOrder" },
      { path: "/nonapprovedproducts" },

  ]
  }
  async function getGraphData() {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/dashboard/sevenDaysFilter`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {
          provider_id: selectedProviderId,
        },
      });

      if (data?.data.length > 0) {
        let z = data.data.map((item: any) => item.total_orders);
        let y = data.data.map((item: any) => item.order_date);
        setpData([...z]);
        setxLables([...y]);
        setChartOptions((prev: any) => {
          return {
            ...prev,
            data: data.data,
          };
        });
      } else {
        setisnoOrders(true);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getPieChartData() {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/dashboard/orderStatuAnalysis`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          provider_id: PieSelectedProviderId,
        },
      });
      setpieChartData(data.data);
      setOptions((prev: any) => {
        return {
          ...prev,
          data: [
            { asset: "Accepted", amount: data.data.accepted ? 5 : 1 },
            { asset: "In-Progress", amount: data.data.in_progress },
            { asset: "Delivered", amount: data.data.completed },
            { asset: "Cancelled", amount: data.data.cancelled },
          ],
        };
      });
    } catch (err) {
      console.log(err);
    }
  }
  async function linegraphFilterHandler(
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    setisnoOrders(false);
    setSelectedProviderId(e.target.value);
  }
  async function piegraphFilterHandler(
    e: React.ChangeEvent<HTMLSelectElement>
  ) {
    setPieSelectedProviderId(e.target.value);
  }
  const NewDate = new Date();
  const todayDate = NewDate.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
  const changeHandler = (e: any) => {
    if (e.target.value === "7") {
      setFilterdata({
        start_date: formattedSevenDaysAgo,
        end_date: dateformat,
      });
    }
    if (e.target.value === "15") {
      setFilterdata({
        start_date: formattedfifteenDaysAgo,
        end_date: dateformat,
      });
    }
    if (e.target.value === "30") {
      setFilterdata({
        start_date: formattedthirtyDaysAgo,
        end_date: dateformat,
      });
    }
  };
  const changeShopifyHandler = (e: any) => {
    if (e.target.value === "7") {
      setShopifyFilterdata({
        start_date: formattedSevenDaysAgo,
        end_date: dateformat,
      });
    }
    if (e.target.value === "15") {
      setShopifyFilterdata({
        start_date: formattedfifteenDaysAgo,
        end_date: dateformat,
      });
    }
    if (e.target.value === "30") {
      setShopifyFilterdata({
        start_date: formattedthirtyDaysAgo,
        end_date: dateformat,
      });
    }
  };
  // const ShopifyCountListing = async () => {
  //   if (ShopifyfilterData.end_date < ShopifyfilterData.start_date) {
  //     // alert("End Date should be ahead of Start Date!");
  //     return;
  //   }
  //   try {
  //     setreqresShopifyLoading(true);
  //     const { data } = await axios({
  //       url: `${apiEndpoint}/dashboard/shopify_order_analysis`,
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
  //       },
  //       data: ShopifyfilterData,
  //     });
  //     if (data?.length > 0) {
  //       let y = data?.map((item: any) => {
  //         return item?.shopify_order_exists;
  //       });
  //       let x = data?.map((item: any) => {
  //         return item?.order_exists;
  //       });
  //       let x2Labelssss = data?.map((item: any) => item?.name);
  //       setshopifyrequest([
  //         { data: [...y], label: "On CMS", id: "pvId" },
  //         { data: [...x], label: "On Platform", id: "uvId" },
  //       ]);
  //     } else {
  //       setShopifyFilterdata({
  //         start_date: formattedSevenDaysAgo,
  //         end_date: dateformat,
  //       });
  //     }
  //     setreqresShopifyLoading(false);
  //   } catch (error) {
  //     setreqresShopifyLoading(false);
  //     console.log(error);
  //   }
  // };
  async function filterChangeHandler(
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) {
    setFilterdata((prev: any) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }
  async function filterShopifyChangeHandler(
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) {
    setShopifyFilterdata((prev: any) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month: string | number = today.getMonth() + 1;
    let day: string | number = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }
  const steps = ["Low", "Medium", "High"];
  const percentageCompleted =
    (pieChartData?.completed / allDashboardData?.total_order) * 100;
  const percentageCompletedInprg =
    (pieChartData?.in_progress / allDashboardData?.total_order) * 100;
  function getData() {
    return [
      { asset: "Accepted", amount: pieChartData.accepted ? 5 : 1 },
      { asset: "In-Progress", amount: pieChartData.in_progress },
      { asset: "Delivered", amount: pieChartData.completed },
      { asset: "Cancelled", amount: pieChartData.cancelled },
    ];
  }
  const [options, setOptions] = useState<any>({
    data: [
      { asset: "Accepted", amount: pieChartData.accepted },
      { asset: "In-Progress", amount: pieChartData.in_progress },
      { asset: "Delivered", amount: pieChartData.completed },
      { asset: "Cancelled", amount: pieChartData.cancelled },
    ],
    width: 300,
    series: [
      {
        type: "pie",
        angleKey: "amount",
        calloutLabelKey: "asset",
        sectorLabelKey: "amount",
        sectorLabel: {
          color: "white",
          fontWeight: "bold",
          formatter: ({ value }: any) => `${value}`,
        },
      },
    ],
  });
  return (
    <ThemeMaker>
      {sidebarShow === 1 ? (
        <div className={`container-fluid  ${styles.mobile_dashboard}`}>
          <div
            className={`d-flex align-items-center justify-content-between my-2 ${styles.heading}`}
          >
            <h1 className="h3 mb-0 d-flex font-18px" style={{ gap: "15px" }}>
            {decode?.data?.user?.seller_id==1?"Admin Dashboard":"Vendor Dashboard"}
            </h1>
            <li className="d-flex align-items-center sm:pr-2 pr-0">
              <a href ="https://savageseller.com/" target="_blank" rel="noreferrer" className="mr-2 d-lg-inline text-#004eff font-18px font-bold">View Online Store</a>
        </li>
          </div>
          <div className={styles.showOnDesktop}>
            <div className="row">
              {isLoading ? (
                <div className="loader-wrapper">
                  <div className="loader"></div>
                </div>
              ) : null}

              {Dashboard.map((item, index) => {
                const abc = paths.find((xyz: any, i: any) => i == index);
                // if (id > 1 && index === 0) {
                //   return null;
                // }
                return (
                  <div className="col-xl-3 col-md-6 mb-4" key={index}>
                    <Link to={`${abc?.path}`}>
                      {" "}
                      <div
                        className={`card border-left-green shadow h-100 py-3 ${
                          styles.cardSttyle
                        } ${
                          (abc?.path).length > 1
                            ? abc.path.substring(1)
                            : "worder"
                        }`}
                      >
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              {index === 0 ? (
                                <div
                                  className={`text-uppercase mb-1 text-white tp ${styles.cardHeading}`}
                                >
                                  {id === 1 ? "Total Product" : "Total Products"}
                                </div>
                              ) : null}
                              {index === 1 ? (
                                <div
                                  className={`text-uppercase mb-1 text-white ts ${styles.cardHeading}`}
                                >
                                  {id === 1 ? "Total Seller" : "Total Users"}
                                </div>
                              ) : null}
                              {index === 2 ? (
                                <div
                                  className={`text-uppercase mb-1 text-white to ${styles.cardHeading}`}
                                >
                                  {id === 1 ? "Total Order" : "Total Order"}
                                </div>
                              ) : null}
                              {index === 3 ? (
                                <div
                                  className={`text-uppercase mb-1 text-white tr ${styles.cardHeading}`}
                                >
                                  {" "}
                                  {id === 1 ? "Total Pending" : "Total Pending"}
                                </div>
                              ) : null}
                              {index === 4 ? (
                                <div
                                  className={`text-uppercase mb-1 ${styles.cardHeading}`}
                                >
                                  {" "}
                                  Revenue
                                </div>
                              ) : null}
                              <div
                                className={`h5 mb-0 font-weight-bold text-white  ${styles.count}`}
                              >
                                {item}
                              </div>
                              {/* <p className={styles.perforamanceFlow}>2% descreased from last week</p> */}
                            </div>
                            <div className={`col-auto ${styles.iconColor} `}>
                              {index === 0 ? (
                                <img src={producticon} alt="Products" />
                              ) : null}
                              {index === 1 ? (
                                <img src={sellericon} alt="Users" />
                              ) : null}
                              {index === 2 ? (
                                <img src={ordericon} alt="Orders" />
                              ) : null}
                              {index === 3 ? (
                                <img src={requesticon} alt="Pending" />
                              ) : null}
                              {index === 4 ? (
                                <img src={requesticon} alt="" />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
            {hasOrder > 0 && (
              <>
                {" "}
                {!isLoading && (
                  <div className={`mt-2 mb-4  d-flex ${styles.graphMain}`}>
                    {xLabels.length > 0 && pData.length > 0 && (
                      <>
                        <div className={`${styles.lineGraphMain}`}>
                          <p className="m-0 py-2 pl-2 d-flex justify-content-between">
                            <span className={`${styles.graphHeading}`} style={{color:"#ffffff"}}>
                              {" "}
                              Total Orders in 20 days
                            </span>
                            {id === 1 ? (
                              <select
                                name=""
                                id=""
                                className="mr-2"
                                style={{
                                  background: "lightgrey",
                                  border: "none",
                                  borderRadius: "0.35rem",
                                  fontSize: "13px",
                                  width: "120px",
                                  outline:"none",
                                }}
                                onChange={linegraphFilterHandler}
                              >
                                <option value="">All Sellers</option>
                                {sellerList
                                  .filter(
                                    (item: any) =>item.approve_status === 1 )
                                  .map((item: any, index) => {
                                    return (
                                      <option
                                        value={item.seller_id}
                                        key={index}
                                      >
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            ) : null}
                          </p>
                          <div
                            className={
                              !isnoOrders
                                ? `d-flex justify-content-center`
                                : "d-none"
                            }
                          >
                            <LineChart
                              sx={{ stroke: "white",fontFamily:"Nunito" }}
                              disableAxisListener
                              width={chartWidth}
                              height={chartHeight}
                              series={[
                                {
                                  data: pData,
                                  label: "Orders Count",
                                  yAxisKey: "leftAxisId",
                                  color: "#4e73df",
                                },
                                // { data: uData, label: 'uv', yAxisKey: 'rightAxisId' },
                              ]}
                              xAxis={[{ scaleType: "point", data: xLabels,label:"Date" }]}
                              yAxis={[{ id: "leftAxisId", label:"Orders" }]}
                              // rightAxis="rightAxisId"
                            />
                          </div>
                          <div
                            style={{ width: "100%" }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <p
                              className={isnoOrders ? ` text-center` : "d-none"}
                            >
                              No Orders Yet !!
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.showOnMobile}>
            <div>
              <AgChartsReact options={chartOptions} />
            </div>
            <div
              className="d-flex justify-content-around my-3"
              style={{ width: "100%", flexWrap: "wrap", gap: "20px" }}
            >
              <div
                className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `}
              >
                <img src={producticon} width={30} height={30} alt="producticon" />
                <p className={`mt-1 mb-1 ${styles.counti}`}>
                  {id === 1
                    ? allDashboardData?.total_product
                    : allDashboardData?.total_sell_product}
                </p>
                <p
                  className=""
                  style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}
                >
                  Product
                </p>
              </div>
              <div
                className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `}
              >
                <img src={ordericon} width={30} height={30} />
                <p className={`mt-1 mb-1 ${styles.counti}`}>
                  {allDashboardData?.total_order}
                </p>
                <p
                  className=""
                  style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}
                >
                  Total Order
                </p>
              </div>
              <div
                className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `}
              >
                <img src={sellericon} width={30} height={30} />
                <p className={`mt-1 mb-1 ${styles.counti}`}>
                  {id === 1
                    ? allDashboardData?.total_seller
                    : allDashboardData?.total_users}
                </p>
                <p
                  className=""
                  style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}
                >
                  {id === 1 ? "Total Seller" : "Total User"}
                </p>
              </div>
              <div
                className={`d-flex flex-column p-2 pl-3 ${styles.mobileBoxes} `}
              >
                <img src={requesticon} width={30} height={30} />
                <p className={`mt-1 mb-1 ${styles.counti}`}>
                  {id === 1
                    ? allDashboardData?.total_nonapprovedproducts
                    : allDashboardData?.sevend_day_orders}
                </p>
                <p
                  className=""
                  style={{ fontSize: "13px", color: "grey", fontWeight: "600" }}
                >
                  {id === 1 ? "Request" : "Weekly Orders"}
                </p>
              </div>
            </div>
            
          </div>
        </div>
      ) : (
        <div
          style={{ minHeight: "90vh"}}
          className="d-flex justify-content-center align-items-center"
        >
          <h5 className="text-center">
            You are not Authorized to access dashboard, Please contact Admin !
          </h5>{" "}
        </div>
      )}
    </ThemeMaker>
  );
}
