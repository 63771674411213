import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import logo from "../../icons/savageseller-logo.jpg";
import { config } from "../../utils/config";
import CryptoJS from "crypto-js";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useParams, useHistory } from "react-router-dom";
export default function ForgetPassword() {
  const history = useHistory();
  const { apiEndpoint, baseNodeapiUrl,communityUrl } = config;
  const [loading, setLoading] = useState(false);
  const [isMailVerified, setisMailVerified] = useState(false);
  const [user, setUser] = useState({
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    email: true,
  });
  const [secretKey, setSecretKey] = useState("yourSecretKey123");
  const forgetPassMannage = async () => {
    const { data } = await axios({
      url: "/user/forget",
      method: "POST",
      data: user,
    });
    setLoading(false);
    if (data.message === "flag2") {
      setUser({ email: "" });
      toast.success("Please check your email to reset password !", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (data.message === "flag1") {
      toast.error("Email is not registered !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (data.message === "flag0") {
      toast.error("404 error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const forgetPassHandler = (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (errorMessage.email) {
      setLoading(false);
      toast.error("Email is required !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      forgetPassMannage();
    }
  };
  const onChangeHandler = async (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: e.target.value ? false : true,
    });
  };
  async function handleSubmit(e: any) {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/checkemail`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { email: user.email },
      });
      if (data.status === 403) {
        toast.error("Mail Doesnot Exist In our Database!");
        return;
      }
      if (data.code === "200") {
        const encryptedEmail = CryptoJS.AES.encrypt(
          user.email,
          secretKey
        ).toString();
        let email = encryptedEmail;
        const { data } = await axios({
          url: `${baseNodeapiUrl}/admin/api/resetPassword`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            email_id: user.email,
            url: `${apiEndpoint}/resetpassword?I5s3kVT3Yaqs67n=${email}`,
          },
        });
        if (data.status == 200) {
          toast.success("We have sent a password reset link on your mail!");
        }
        console.log(data);
      }
    } catch (error) {
      toast.error("Something Went Wrong !");
      console.log(error);
    }
  }
  const [verifyotp, setVerifyotp] = useState(false);
  const [sendotp, setSendotp] = useState(true);
  const [changepassword, setChangepassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [email, setEmail] = useState('')
  const [showNewpassword, setShowNewPassword] = useState(false)
  const [showConfirmpassword, setShowConfirmPassword] = useState(false)
  const handleResetPassword = async (e:any) => {
    console.log(e)
    e.preventDefault()
   
    if (newpassword !== "" && confirmpassword !== "") {
        try {
            const data = {
                email: email,
                password: newpassword
            }
            console.log('set password data >>>>>>', data)
            const response = await axios.post(`${apiEndpoint}/user/reset-password`, data)
            console.log('new set password is >>>>>>>>', response)

            if (response.status == 200) {
              await axios.post(`${communityUrl}/user/reset-password`, {
                email: email,
                newPassword: newpassword // Hardcoded password for multivendor API, adjust if needed
              });
                toast.success("Password changed successfully", {
                    autoClose: 3000,
                });
                history.push("/login");
                
            }
            else {
                toast.error("Failed to change the password", {
                    autoClose: 3000,
                });
                
            }
        } catch (error) {
            toast.error("Failed to change the password", {
                autoClose: 3000,
            });
        
            console.error('set new password error', error)
        }
    } else {
        toast.error("fill the password", {
            autoClose: 3000,
        });
       
    }
}
const handleSubmitotp = async (e:any) => {
  e.preventDefault();

  if (email !== "") {
      try {
          const data = {
              email: email,
          };
          const response = await axios.post(`${apiEndpoint}/user/forgot-password`, data);
          console.log('response otp is >>>>>>>>>>>>>', response);
          if (response.data.success === true) {
              
              // setEmail('');    
              toast.success('OTP sent on your mail', {
                  autoClose: 3000,
              });
              setVerifyotp(true)
              setSendotp(false)
          }
          else if (response.data.success === false) {
              
              toast.error(response.data.message, {
                  autoClose: 3000,
              });
          }
          else {
              
              toast.error('User not found"', {
                  autoClose: 3000,
              });
          }
      } catch (error) {
          console.error('OTP response error', error);
         
          toast.error('Failed to send OTP', {
              autoClose: 3000,
          });
      }
  } else {
      toast.error('Please fill in the email');
 
  }

};




const handleVerifyotp = async (e:any) => {
  e.preventDefault();
 
  try {
      const data = {
          email: email,
          otp: parseInt(otp)
      }
      console.log('verifying data is >>>>>>>', data)
      const response = await axios.post(`${apiEndpoint}/user/verify-otp`, data)
      console.log('verify otp is >>>>>>>>>>>>>>>>', response)
      if (response.data.success === true) {
         
          toast.success("Verified OTP", {
              autoClose: 3000,
          });
          setVerifyotp(false)
          setSendotp(false)
          setChangepassword(true)
      }
      else {
          toast.error("Failed to change the password", {
              autoClose: 3000,
          })
          
      }
  } catch (error) {
      console.error('Verify OTP response error', error);
      
  }
};

  return (
    <>
                <section
          className="d-flex align-items-center"
          style={{ height: "100vh", backgroundColor: "#dfe7f0" }}
        >
          <div className="container main_container">
            <div className="row col-sm-8 m-auto shadow-regular align-items-center bg-white rounded rounded-4 shadow-sm p-3 mb-5 flex_direction">
             
              <div
                className="loginFormat col-lg-12 col-sm-12 col-md-12 p-1"
                id="padding"
              >
                <div className="imagesSetup">
                    <img
                      className="w-50 d-block mx-auto"
                      src="img/savageseller-logo.jpg"
                      alt="loginCard"
                    />
                  </div>
        <div className="flex justify-center items-center h-screen bg-[#E0E9F7] p-2">
            {sendotp === true ?
                <form className="space-y-4  md:space-y-5 w-full p-6 bg-white rounded-lg dark:border md:mt-0 sm:max-w-md sm:p-8" onSubmit={handleSubmitotp}>
                   
                    <div className="d-flex flex-column">
                        <label
                            htmlFor="email"
                            className="d-block mb-2 text-sm font-bold text-gray-900"
                        >
                            Your Email
                        </label>
                        
                        <input
                            type="email"
                            name="email"
                            placeholder="E-mail"
                            className="bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-success font-medium rounded-lg text-sm px-5 py-2 text-center d-block mx-auto mt-4"
                    >
                        Send OTP
                    </button>
                </form> : null}

            {verifyotp ?
                <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5 w-full p-6 bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md sm:p-8" onSubmit={handleVerifyotp}>
                    
                    <div className="d-flex flex-column">
                        <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Enter 6 Digit OTP
                        </label>
                        <input
                            type="text"
                            name="otp"
                            placeholder="OTP"
                            className="bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            maxLength={6}
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-success font-medium rounded-lg text-sm px-5 py-2 text-center mt-4"
                    >
                        Verify OTP
                    </button>
                </form> : null}

            {changepassword ?
                <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5 w-full p-6 bg-white rounded-lg  dark:border md:mt-0 sm:max-w-md sm:p-8" onSubmit={handleResetPassword}>
                    
                    <div className="d-flex flex-column position-relative">
                        <label
                            htmlFor="password"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            New Password
                        </label>
                        <input
                            type={showNewpassword ? "text" : "password"}
                            name="password"
                            id="password"
                            placeholder="Enter Your New Password"
                            className="position-relative bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            
                            value={newpassword}
                            onChange={(e) => setNewpassword(e.target.value)}
                        />
                        <div
                            className="position-absolute top-10 right-0 pr-3 flex items-center cursor-pointer"
                            style={{right:"0",top:"53%"}}
                            onClick={() => setShowNewPassword(!showNewpassword)}
                        >
                            {showNewpassword ? <IoEye /> : <IoEyeOff />}
                        </div>
                    </div>
                    <div  className="d-flex flex-column position-relative">
                        <label
                            htmlFor="confirm-password"
                            className="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Confirm password
                        </label>
                        <input
                            type={showConfirmpassword ? "text" : "password"}
                            name="password"
                            id="password"
                            placeholder="Confirm Password"
                            className={`position-relative bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500 ${newpassword !== confirmpassword ? 'border-red-500' : ''
                                }`}
                           
                            value={confirmpassword}
                            onChange={(e) => setConfirmpassword(e.target.value)}
                        />
                        <div
                            className="position-absolute top-10 right-0 pr-3 flex items-center cursor-pointer"
                            style={{top:"53%",right:"0"}}
                            onClick={() => setShowConfirmPassword(!showConfirmpassword)}
                        >
                            {showConfirmpassword ? <IoEye /> : <IoEyeOff />}
                        </div>
                       
                    </div>
                    {newpassword !== confirmpassword && (
                            <p className="text-red-500 text-sm mt-1">
                                Passwords do not match.
                            </p>
                        )}
                    <button
                        type="submit"
                        className="mt-4 w-full text-white hover:text-[#005AE6] border bg-success border-[#005AE6] hover:bg-white font-medium rounded-lg text-sm px-5 py-2 text-center"
                        disabled={newpassword !== confirmpassword ? true : false}
                    >
                        Change Password
                    </button>
                </form> : null}

        </div>
        </div>
        </div></div></section>
    </>
);
}
