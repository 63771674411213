import styles from "./CustomerQuery.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { config } from "../../utils/config";
import { tokenDecode } from "../../utils/helper";
import {
  format,
  isToday,
  isYesterday,
  isBefore,
  subDays,
  formatDistanceToNow,
} from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
const { apiEndpoint, storeUrl } = config;

export default function CustomerQuery() {
  const decode = tokenDecode();
  const [customerQuery, setCustomerQuery] = useState([]);
  const [queryModal, setQueryModal] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [sellerReply, setSellerReply] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  let seller_id = decode?.data?.user?.seller_id;

  useEffect(() => {
    setIsLoading(true);
    const getCustomerQuery = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/notification/getNotificationfromshopify`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: { seller_id: seller_id },
        });
        // console.log("queries", data);

        if (data.status === 200) {
          const sortedQueries = data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setCustomerQuery(sortedQueries);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching notifications", error);
        setIsLoading(false);
      }
    };
    getCustomerQuery();
  }, [seller_id]);

  const boxStyle = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "0.35rem",
    bgcolor: "#ffffff",
    boxShadow: "0 3px 10px rgb(0,0,0,0.2)",
    outline: "none",
    p: 2,
  };

  const formatTiming = (timing) => {
    const timingDate = new Date(timing);
    const now = new Date();

    if (isToday(timingDate)) {
      if (now - timingDate < 60 * 60 * 1000) {
        return `Today, ${format(timingDate, "hh:mm a")}`;
      } else {
        return `${formatDistanceToNow(timingDate, { addSuffix: true })}`;
      }
    } else if (isYesterday(timingDate)) {
      return `Yesterday, ${format(timingDate, "hh:mm a")}`;
    } else if (isBefore(timingDate, subDays(now, 6))) {
      return format(timingDate, "MMMM dd, yyyy");
    } else {
      return `${format(timingDate, "EEEE, hh:mm a")}`;
    }
  };

  const handleQueryModal = (query) => {
    setSelectedQuery(query);
    setQueryModal(true);
  };

  const closeQueryModal = () => {
    setQueryModal(false);
    setSelectedQuery(null);
    setSellerReply("");
  };

  const handleReplyChange = (e) => {
    setSellerReply(e.target.value);
  };

  const handleSendReply = async () => {
    setIsSent(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/notification/sendCustomerReply`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: { ...selectedQuery, sellerReply: sellerReply },
      });
      // console.log("data__", data);
      if (!sellerReply) {
        setIsSent(false);
        toast.error("Please add your reply before sending.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      if (data.status === 200) {
        setIsSent(false);
        closeQueryModal();
        toast.success("Successfully sent the reply !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("404 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setIsSent(false);
      console.error("Error sending reply", error);
    }
  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const getNameInitials = (user) => {
    const nameParts = user.name.split(" ");
    const firstLetters = nameParts.map((part) => part[0]).join("");
    return firstLetters.toUpperCase(); 
  };

  return (
    <>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "60vh" }}
        >
          <CircularProgress />
        </div>
      ) : customerQuery.length > 0 ? (
        customerQuery.map((item) => (
          <div
            key={item._id}
            className={`d-flex align-items-center justify-content-between p-3 ${styles.main} bg-light`}
          >
            <div className="d-flex align-items-center justify-content-start col-md-10">
              <div className="d-flex mr-2">
                <div className={`${styles.queryindex}`}>
                  <span className={`${styles.customerName}`}>{getNameInitials(item)}</span>
                </div>
              </div>
              <div className={`ml-2 d-flex flex-column ${styles.queryMsg}`}>
                <span className={`${styles.queryContent}`}>{capitalizeFirstLetter(item.content)}</span>
                <span className={`mt-2 text-capitalize ${styles.queryTiming}`}>
                  {formatTiming(item.createdAt)}
                </span>
              </div>
            </div>
            <div className="p-2 col-md-2 text-right">
              <span
                className={`p-2 ${styles.openQuery}`}
                onClick={() => handleQueryModal(item)}
              >
                Open
              </span>
            </div>
          </div>
        ))
      ) : (
        <div
          className={`d-flex align-items-center justify-content-center text-dark ${styles.noQuery}`}
        >
          No Query Found
        </div>
      )}
      <Modal
        open={queryModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle} className="col-md-6">
          <div className="modal-content">
            <div className="modal-header p-2 d-flex align-items-center justify-content-between">
              <p
                className={`modal-title ${styles.queryLabel}`}
                style={{ letterSpacing: "3px" }}
              >
                Query Details:
              </p>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={closeQueryModal}
              >
                <span style={{ cursor: "pointer" }}>
                  <CloseIcon />
                </span>
              </button>
            </div>
            {selectedQuery && (
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-5">
                    <div className="mb-2">
                      <span
                        className={`${styles.queryLabel} mr-2 ${styles.queryTitle}`}
                      >
                        Name:
                      </span>{" "}
                      <span className={`${styles.queryValue}`}>
                        {selectedQuery.name}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="mb-2">
                      <span
                        className={`${styles.queryLabel} mr-2 ${styles.queryTitle}`}
                      >
                        E-mail:
                      </span>{" "}
                      <span className={`${styles.queryValue}`}>
                        {selectedQuery.customer_email}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="mb-2">
                      <span
                        className={`${styles.queryLabel} mr-2 ${styles.queryTitle}`}
                      >
                        Timing:
                      </span>{" "}
                      <span className={`${styles.queryValue} text-capitalize`}>
                        {formatTiming(selectedQuery.createdAt)}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="mb-2">
                      <span
                        className={`${styles.queryLabel} mr-2 ${styles.queryTitle}`}
                      >
                        Product Title:
                      </span>{" "}
                      <span className={`${styles.queryValue}`}>
                        {selectedQuery.productTitle}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-2 d-flex">
                      <span
                        className={`${styles.queryLabel} mr-2 ${styles.queryTitle}`}
                        style={{ marginTop: "2px" }}
                      >
                        Query:
                      </span>{" "}
                      <span className={`${styles.queryValue}`}>
                        {selectedQuery.content}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-2">
                      <span
                        className={`${styles.queryLabel} mr-2 ${styles.queryTitle}`}
                      >
                        Product Link:
                      </span>
                      <a
                        href={`${storeUrl}products/${selectedQuery.producthandle}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Product
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <textarea
                      rows="4"
                      className="form-control"
                      placeholder="Type your reply here..."
                      value={sellerReply}
                      onChange={handleReplyChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSendReply}
                style={{ width: "120px" }}
              >
                {isSent ? "Sending..." : "Send Reply"}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
