import React from "react";
import CopyrightIcon from '@mui/icons-material/Copyright';

export default function Footer() {
  return (
    <footer className="sticky-footer">
      <div className="container mx-auto">
        <div className="copyright text-center my-auto d-flex align-items-center justify-content-center">
        <span style={{fontSize:"18px",marginRight:"2px"}}>©</span> <span> 2024-Savage Seller</span>
        </div>
      </div>
    </footer>
  );
}
