/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { useHistory  } from "react-router-dom";
import { show, hide } from "react-functional-modal";
import styled from "styled-components";
import ThemeMaker from "../../components/thememaker";
import CircularProgress from "@mui/material/CircularProgress";
import { IoIosLink,IoIosCheckmark } from "react-icons/io";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const { apiEndpoint,storeUrl,communityUrl } = config;
const aws = require("aws-sdk");
const ImageContainer = styled.div`
  border: 1px solid black;
  border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 100% !important;
    height: 80%;
    object-fit: contain;
  }

  button {
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
`;

export default function createProduct() {
 
  const decode = tokenDecode();
  let sellerId = decode?.data?.user?.seller_id;
  let sellerEmail = decode?.data?.user?.email;
  const [read, setRead] = useState(false);
  const [pageNamem, setpageName] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [uploading, setUpLoading] = useState(false);
  const [showMenu, setshowMenu] = useState(false);
  const [brandlist, setbrandlist] = useState([]);
  let rawSubCatData = {
    parent_id: "",
    name: "",
    status: true,
    id: "",
  };
  const returnWindowLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const [Subcategorydata, setSubcategorydata] = useState(rawSubCatData);
  const [taxData, setTaxData] = useState([]);
  const [readOnly, setreadOnly] = useState(false);
  const [catName, setCatName] = useState("");
  const [imgUpload, setImageUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellerData, setSellerData] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [sellerCommunities,setSellerCommunities] = useState([]);
  const [communityIds,setCommunityIds] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [shippingMsg, setShippingMsg] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [initialCollection, setInitialCollection] = useState(null);
  const [newPublicPlace, setNewPublicPlace] = useState("");
  
  const dropdownRef = useRef(null);

  const main_collection = [
    {
      displayName: "Clothing Accessories",
      handle: "clothing-accessories",
      sub_collections: [
        { handle: "men-s-clothing", name: "Men's Clothing" },
        { handle: "women-s-clothing", name: "Women's Clothing" },
        { handle: "kids-baby-clothing", name: "Kids & Baby Clothing" },
      ],
    },
    {
      displayName: "Electronics",
      handle: "electronics",
      sub_collections: [
        { handle: "mobile-phones-accessories", name: "Mobile Phones & Accessories" },
        { handle: "computers-laptops", name: "Computers & Laptop" },
        { handle: "home-electronics", name: "Home Electronics" },
      ],
    },
    {
      displayName: "Home & Living",
      handle: "home-living",
      sub_collections: [
        { handle: "furniture ", name: "Furniture" },
        { handle: "home-decor", name: "Home Decor" },
        { handle: "kitchen-dining", name: "Kitchen & Dining" },
      ],
    },
    {
      displayName: "Beauty & Personal Care",
      handle: "beauty-personal-care",
      sub_collections: [
        { handle: "skincare", name: "Skincare" },
        { handle: "makeup", name: "Makeup" },
        { handle: "hair-care", name: "Hair Care" },
        { handle: "fragrances-1", name: "Fragrances" },
        { handle: "personal-care-appliances", name: "Personal Care Appliances" },
      ],
    },
    {
      displayName: "Health & Wellness",
      handle: "health-wellness",
      sub_collections: [
        { handle: "supplements", name: "Supplements" },
        { handle: "fitness-equipment", name: "Fitness Equipment" },
        { handle: "yoga-meditation", name: "Yoga & Meditation" },
        { handle: "medical-supplies", name: "Medical Supplies" },
      ],
    },
    {
      displayName: "Sports & Outdoor",
      handle: "sports-outdoor",
      sub_collections: [
        { handle: "sports-equipment", name: "Sports Equipment" },
        { handle: "outdoor-recreation", name: "Outdoor Recreation" },
      ],
    },
    {
      displayName: "Toys & Hobbies",
      handle: "toys-hobbies",
      sub_collections: [
        { handle: "toys", name: "Toys" },
        { handle: "hobbies-crafts", name: "Hobbies & Crafts" },
      ],
    },
    {
      displayName: "Books, Media & Music",
      handle: "books-media-music",
      sub_collections: [
        { handle: "books", name: "Books" },
        { handle: "music-movies", name: "Music & Movies" },
        { handle: "instruments", name: "Instruments" },
      ],
    },
    {
      displayName: "Automotive",
      handle: "automotive",
      sub_collections: [
        { handle: "car-accessories", name: "Car Accessories" },
        { handle: "vehicles", name: "Vehicles" },
      ],
    },
    {
      displayName: "Office Supplies",
      handle: "office-supplies",
      sub_collections: [
        { handle: "office-furniture", name: "Office Furniture" },
        { handle: "supplies", name: "Supplies" },
        { handle: "printers-scanners", name: "Printers & Scanners" },
      ],
    },
    {
      displayName: "Miscellaneous",
      handle: "miscellaneous",
      sub_collections: [
        { handle: "gift-items", name: "Gift Items" },
        { handle: "vintage-collectibles", name: "Vintage & Collectibles" },
        { handle: "others", name: "Others" },
      ],
    },
  ];
  
  const rawData = {
    seller_id: decode?.data?.user?.seller_id,
    name: "",
    //net_qty: "",
    quantity: "",
    description: "",
    category_id: 0,
    mrp: 0,
    selling_price: 0,
    tax_included_price: 0,
    tax: 0,
    tags: "",
    image: [],
    sku: "",
    hsn: "",
    status: false,
    returnable: "",
    cancelable: "",
    return_window: "",
    seller_pickup_return: "",
    cod: "",
    tax_included: "",
    replaceable: "",
    brand: "",
    reference_id: 0,
    rent: "no",
    rentQuantity: "",
    rentPrice: "",
    privacy_status:"",
    selectedGroups:[],
    pickup_location:"",
    pickup_enable:false,
    drop_off_location:"",
    drop_off_enable:false,
    public_place:"",
    public_places:[],
    public_place_enable:false,
    final_selling_price:0,
    selected_shipping: ["default_shipping"],
    default_shipping_enable: true,
  };
  const rawDataError = {
    name: false,
    description: false,
    category_id: false,
    //net_qty: false,
    quantity: false,
    mrp: false,
    selling_price: false,
    tax_included_price: false,
    tax: false,
    tags: false,
    image: false,
    sku: false,
    hsn: false,
    status: false,
    returnable: false,
    cancelable: false,
    return_window: false,
    seller_pickup_return: false,
    cod: false,
    tax_included: false,
    replaceable: false,
    brand: false,
    rent: false,
    rentQuantity: false,
    rentPrice: false,
    privacy_status:false,
    pickup_location:false,
    drop_off_location:false,
    public_place:false,
    public_places:false,
    final_selling_price:false,
    selected_shipping:false,
    default_shipping_enable: false,
  };

  const [product, setProduct] = useState(rawData);
  const [productErr, setProductErr] = useState(rawDataError);
  const[allVariants,setAllVariants] = useState({});
  const[allMetafields,setAllMetafields] = useState({});
  const[productUrl,setProductUrl] = useState(null);
  const [copied, setCopied] = useState(false);
  const [msgopen, setMsgOpen] = useState(false);
  const [vertical, setVertical] = useState("top");
  const [horizontal, setHorizontal] = useState("center");
  const [finalSellingPrice,setFinalSellingPrice] = useState(0);
  const [hasMetafieldsProcessed, setHasMetafieldsProcessed] = useState(false);
  const [linkPopUp, setLinkPopup] = useState(false);
  const [isProductCreated, setIsProductCreated] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [createdProductLink, setCreatedProductLink] = useState(null);
  const [openMainCategory, setOpenMainCategory] = useState(null);
  const [openSubCategory, setOpenSubCategory] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({ main: null, sub_collection: null, });
  const [selectedCollection, setSelectedCollection] = useState({
    handle:"",
    title:""
  });
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [mainCategoryIndex, setMainCategoryIndex] = useState(null);
  const categoryDropdownRef = useRef(null);

  const handleMsgClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMsgOpen(false);
  };
  const handleLinkClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLinkPopup(false);
    history.push("/catalog");
  };

  let params = useParams();
  const { id } = params;
  const history = useHistory();
  const currentPath = history.location.pathname;

  useEffect(() => {
      setMsgOpen(true); 
      setVertical("top");
      setHorizontal("center");
  }, [currentPath]);

  useEffect(() => {
      setLinkPopup(true); 
      setVertical("top");
      setHorizontal("center");
  }, [isProductCreated]);

  const [rentalInfo, setRentalInfo] = useState([
    { day: "1 Day", price: "", quantity: "" },
    { day: "2 Day", price: "", quantity: "" },
    { day: "3 Day", price: "", quantity: "" },
    { day: "4 Day", price: "", quantity: "" },
    { day: "5 Day", price: "", quantity: "" },
    { day: "6 Day", price: "", quantity: "" },
    { day: "Weekly", price: "", quantity: "" },
  ]);
  const defaultRentalOptions = [
    { day: "1 Day", price: "", quantity: "" },
    { day: "2 Day", price: "", quantity: "" },
    { day: "3 Day", price: "", quantity: "" },
    { day: "4 Day", price: "", quantity: "" },
    { day: "5 Day", price: "", quantity: "" },
    { day: "6 Day", price: "", quantity: "" },
    { day: "Weekly", price: "", quantity: "" },
  ];
  const [items, setItems] = useState([]);
  const [rentOnlyQty, setRentOnlyQty] = useState(product.quantity);
  
  const mergeRentalOptions = (defaultOptions, fetchedOptions) => {
    return defaultOptions.map((defaultOption) => {
      const matchingFetchedOption = fetchedOptions.find(
        (fetchedOption) => fetchedOption.day === defaultOption.day
      );
      return matchingFetchedOption
        ? { ...defaultOption, ...matchingFetchedOption }
        : defaultOption;
    });
  };
  
  const isRentalInfoFilled = () => {
    const filledCount = rentalInfo.filter(info => info.price !== "" && info.quantity !== "").length;
    return filledCount >= 2;
  };

  const open = () => {
    show(
      <ImageContainer>
        <img src={product.image} alt="" />
        <button
          onClick={() => {
            hide("key#1");
          }}
        >
          x
        </button>
      </ImageContainer>,
      { key: "key#1" }
    );
  };

  const handleSubcategoryChange = (id) => {
    setSubcategorydata({ ...Subcategorydata, parent_id: id });
  };

  const [Nadata, setNadata] = useState([]);

  useEffect(() => {
    taxListHanler();
    categoryListing();

    brandlisting();
    if (id) {
      if (id) {
        setpageName(false);
        getProduct();

        // setProduct(JSON.parse(user))
      } else {
        setpageName(true);
      }
      getProduct();

      // if (current_sellerid > 1) {
      //   setreadonly(true)
      // }
      // setProduct(JSON.parse(user))
    } else {
      // setpageName(true);
      getadditionaldetails();
    }
  }, []);

  async function brandlisting() {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/brand/getBrandList`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });

      if (data.status === 200) {
        setbrandlist(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const getSellerData = async () => {
      const currentPath = window.location.pathname.split('/');
      const targetSegment = currentPath[1];
      setIsLoading(true);
      setMsgOpen(false);
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/getseller`,
          method: 'POST',
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`
          },
          data: {
            seller_id: sellerId,
          },
        });
  
        if (data && data.response) {
          if(targetSegment === 'createSellerProduct'){
            setProduct((prevProduct) => ({
              ...prevProduct,
              pickup_location: data.response.personal_address,
              drop_off_location:data.response.dropoff,
              // public_place:data.response.meetpublicplace,
              public_places:data.response.meetpublicplace,
            }));
          }
          setSellerData(data.response);
          setIsLoading(false);
          setMsgOpen(true);
        }
      } catch (error) {
        console.error("Error fetching seller data:", error);
        setIsLoading(false);
      }
    };
    getSellerData();
  }, [sellerId,]);
  

  async function getadditionaldetails() {
    const { data } = await axios({
      url: `${apiEndpoint}/seller/sellerShop`,
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("us_st_d")}`,
      },
    });

    if (data.status === 200) {
      let adddata = data.response;
      // console.log("adddata", adddata)
      setProduct({
        ...product,
        cod: adddata.cod,
        replaceable: adddata.replaceable,
        returnable: adddata.returnable,
        cancelable: adddata.cancelable,
        return_window: adddata.return_window,
        seller_pickup_return: adddata.seller_pickup_return,
      });
    }
  }

  const getProduct = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/getproductseller`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: { id: id },
      });
      // console.log("getProduct >---------> ", data);
      
      if (data.status === 200) {
        setProduct(data.data);
        if (data?.data?.rent === "yes" || data?.data?.rent === "rentonly") {
          const fetchedRentalInfo = data.data.rentVarient || [];
          const updatedRentalInfo = mergeRentalOptions(defaultRentalOptions, fetchedRentalInfo);
          setRentalInfo(updatedRentalInfo);
        }
        const parsedData = data.response.map((item) => ({
          id: item.id,
          parent_id: item.parent_id,
          cat_name: item.cat_name,
          status: item.status,
          subcategory: item.subcategory || [],
        }));
        setNadata(parsedData);
      }
    } catch (error) {}
  };

  const MakeCategory = ({ cat_name, subcategory, id }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(
      product.categories_id && product.categories_id.includes(id)
    );
    const [subCategoryOpen, setSubCategoryOpen] = useState(
      Array(subcategory.length).fill(false)
    );
    const subCategoryChecker = subcategory && subcategory.length > -1;

    const handleCategoryClick = () => {
      setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (event, catName) => {
      const newCheckedState = event.target.checked;
      setIsChecked(newCheckedState);
      onCategoryClick(id, newCheckedState);
      setCatName(catName);
    };

    const handleSubCategoryClick = (index) => {
      const newSubCategoryOpen = [...subCategoryOpen];
      newSubCategoryOpen[index] = !newSubCategoryOpen[index];
      setSubCategoryOpen(newSubCategoryOpen);
    };

    function onCategoryClick(id, checked) {
      if (checked) {
        setProduct({
          ...product,
          categories_id: [...product.categories_id, id],
        });
      } else {
        setProduct({
          ...product,
          categories_id: product.categories_id.filter(
            (category_id) => category_id !== id
          ),
        });
      }
    }

    useEffect(() => {
      setIsChecked(product.categories_id && product.categories_id.includes(id));
    }, [id]);

    return (
      <div className="makeCategory_subCategory">
        <ul>
          <li className="my-0" key={id}>
            {subCategoryChecker && (
              <span onClick={handleCategoryClick}>
                {subCategoryChecker ? (
                  isOpen ? (
                    <RemoveIcon />
                  ) : (
                    <AddIcon />
                  )
                ) : (
                  ""
                )}
              </span>
            )}
            <input
              type="checkbox"
              value={id}
              name="sub-category"
              checked={isChecked}
              onChange={(e) => handleCheckboxChange(e, cat_name)}
            />{" "}
            {cat_name}
          </li>
          {isOpen &&
            subCategoryChecker &&
            subcategory.map((item, index) => (
              <MakeCategory
                key={item.id}
                {...item}
                isOpen={subCategoryOpen[index]}
                onSubCategoryClick={() => handleSubCategoryClick(index)}
                hideIcon={index === subcategory.length - 1}
              />
            ))}
          <hr style={{ marginBottom: "0" }} />
        </ul>
      </div>
    );
  };

  const categoryListing = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/categories/getcollectionlistinshopifystore`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Access-Token": "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",
        },
      });
      if (data.status === 201) {
        setCategoryList(data.response);
      }
    } catch (error) {}
  };
  
  const errorHandler = (e) => {
    if (e.target.value) {
      setProductErr({ ...productErr, [e.target.name]: false });
    } else {
      setProductErr({ ...productErr, [e.target.name]: true });
    }
  };

  const taxListHanler = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/tax/gettaxlist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      // console.log("tax listing", data);
      if (data.status === 200) {
        setTaxData(data.data);
      }
    } catch (error) {}
  };

  const priceHandler = (e) => {
    // console.log("e.target.name", e.target.name);
    if (e.target.name === "selling_price") {
      const percentAmmount =
        (parseFloat(product.tax) / 100) * parseFloat(e.target.value);
      setProduct((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: product?.tax_included
          ? e.target.value
          : parseFloat(e.target.value) + parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax") {
      const percentAmmount =
        (parseFloat(e.target.value) / 100) * parseFloat(product.selling_price);
      setProduct((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: product?.tax_included
          ? product?.tax_included_price
          : parseFloat(product.selling_price) +
            parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax_included") {
      const percentAmmount =
        (parseFloat(product.tax) / 100) * parseFloat(product.selling_price);
      setProduct({
        ...product,
        [e.target.name]: !product?.tax_included,
        tax_included_price: !product?.tax_included
          ? product.selling_price
          : percentAmmount + parseFloat(product.selling_price),
      });
    }
  };

  const productImageHandler = async (e) => {
    try {
      setImageUpload(true);
      const files = Array.from(e.target.files); // Get array of files
      const image = [];

      for (const file of files) {
        let myFile = file.name.split(".");
        let myFileType = myFile[myFile.length - 1];
        const s3 = new aws.S3({
          accessKeyId: config?.awsKeyId,
          secretAccessKey: config?.awsSecretKey,
        });
        const params = {
          Bucket: "savageseller",
          Key: `${uuidv4()}.${myFileType}`,
          Body: file,
          ContentType: file.type,
        };
        let result = await s3.upload(params).promise();
        const { Location } = result;
        image.push(Location); // Store image URL in array
      }

      // Update state with the array of image URLs
      setProduct({ ...product, image: [...product.image, ...image] });
      setImageUpload(false);
    } catch (exception) {
      console.log(exception);
    }
  };
  const deleteImage = (index) => {
    const updatedImages = [...product.image];
    updatedImages.splice(index, 1);
    setProduct({ ...product, image: updatedImages });
  };
  const createVariants = () => {
    const variants = rentalInfo
      .filter((info) => info.price && info.quantity)
      .filter((variant) => variant.title !== "Buy" && variant.option1 !== "Buy")
      .map((info) => ({
        price: info.price,
        inventory_management: "shopify",
        inventory_quantity: info.quantity,
        compare_at_price: info.price,
        tags: product.tags,
        title: info.day + ` Rent`,
        option1: info.day + ` Rent`,
        day: info.day,
        quantity: info.quantity,
      }));

    variants.push({
      price: product.selling_price,
      inventory_management: "shopify",
      inventory_quantity: product.quantity,
      compare_at_price: product.mrp,
      tags: product.tags,
      title: "Buy",
      option1: "Buy",
    });
    return variants;
  };
  const createVariants1 = () => {
    const variants = rentalInfo
     .filter((info) => info.price && info.quantity)
      .filter((variant) => variant.title !== "Buy" && variant.option1 !== "Buy")
      .map((info) => ({
        price: info.price,
        inventory_management: "shopify",
        inventory_quantity: info.quantity,
        compare_at_price: info.price,
        tags: product.tags,
        title: info.day + ` Rent`,
        option1: info.day + ` Rent`,
        day: info.day,
        quantity: info.quantity,
      }));
    // console.log(variants);
    return variants;
  };
  // console.log('rentalInfo',rentalInfo);
  const forbiddenKeywords = [
    "Nike",
    "Jordan",
    "Adidas",
    "vape",
    "cannabis",
    "marijuana",
    "CBD",
    "THC",
    "weed",
    "firearm",
    "gun",
    "bullet",
    "dope",
    "pistol",
    "cleaning supplies",
    "cleaning equipment",
    "holsters",
    "riffle",
    "shotguns",
    "Air pistols",
    "sawa",
    "range finders",
    "rifle parts",
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the entered value contains any forbidden keywords
    const containsForbiddenKeyword = forbiddenKeywords.some((keyword) =>
      value.toLowerCase().includes(keyword.toLowerCase())
    );
    if (containsForbiddenKeyword) {
      setProductErr({
        ...productErr,
        name: "Product name contains forbidden keywords",
      });
      if(name === 'brand'){
        setProductErr({
          ...productErr,
          brand:'Brand name contains forbidden keywords',
        });
      }
    } else {
      setProductErr({ ...productErr, name: "",brand:"This field is required!" });
      setProduct({ ...product, [name]: value });
    }
  };
  const handleInput = async (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log(product);
    const errorEmptyAlert = () => {
      toast.error("Please fill the required fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      return;
    };

    const {
      name,
      selling_price,
      sku,
      image,
      brand,
      quantity,
      mrp,
      rent,
      rentQuantity,
      rentPrice,
      drop_off_location,
      public_place,
      privacy_status,
      pickup_enable, 
      drop_off_enable, 
      public_place_enable,
      selected_shipping,
      category_id
    } = product;
    // console.log(image);
    if (
      name &&
      selling_price &&
      sku &&
      image &&
      brand !== "" &&
      quantity &&
      mrp &&
      privacy_status !== "" &&
      category_id !== ""
    ) {
      
      if ((rent === "yes" || rent === "rentonly") && !isRentalInfoFilled()) {
        toast.error("Please fill atleast 2 rent price and rent quantity!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        return;
      }
      const productImages = image;

      const images = productImages.map((src) => ({ src }));
      let updatedTags = product.tags ? product.tags.split(",") : [];
      // __Add privacy_status to tags as product tag__
      if (privacy_status && !updatedTags.includes(privacy_status)) {
        updatedTags.push(privacy_status);
      }
      /* Code For 'multi_vendor' tag is always included */
      if (!updatedTags.includes("multi_vendor")) {
        updatedTags.push("multi_vendor");
      }
      if (rent === "yes" || rent === "rentonly") {
        if (!updatedTags.includes("rental")) {
          updatedTags.push("rental");
        }
      } else {
        updatedTags = updatedTags.filter((tag) => tag !== "rental");
      }
      updatedTags = updatedTags.join(",");
      try {
        let variants;
        if (rent === "yes") {
          variants = createVariants();
        } else if (rent === "rentonly") {
          variants = createVariants1();
        } else {
          variants = [
            {
              price: product.selling_price,
              sku: product.sku,
              inventory_management: "shopify",
              inventory_quantity: product.quantity,
              compare_at_price: product.mrp,
              tags: product.tags,
            },
          ];
        }
        // console.log(variants);
        const metafields = [
          {
            namespace: "custom",
            key: "drop_off_location",
            value: selected_shipping.includes('dropoff') && drop_off_enable 
              ? product.drop_off_location 
              : "", 
            type: "multi_line_text_field",
          },
          {
            namespace: "custom",
            key: "pickup_location",
            value: selected_shipping.includes('pickup') && pickup_enable 
              ? product.pickup_location 
              : "", 
            type: "multi_line_text_field",
          },
          {
            namespace: "custom",
            key: "meet_public_place",
            value: selected_shipping.includes('meetup') && public_place_enable 
              ? JSON.stringify(product.public_places.filter(place => place.trim() !== ""))
              : "", 
            type: "list.single_line_text_field",
          },
        ];
        //shopify api
        const shopifyProductResponse = await axios({
          method: "POST",
          url: `${apiEndpoint}/product/addproductinshopifystore`, // Shopify API endpoint to add product
          headers: {
            "Content-Type": "application/json",
            "X-Shopify-Access-Token": "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",
          },
          data: {
            product: {
              title: product.name,
              body_html: product.description,
              vendor: decode?.data?.user?.seller_id,
              status: "active",
              tags: updatedTags,
              product_type: product.brand,
              published_at: new Date(),
              variants: variants,
              metafields:metafields,
              options: [
                {
                  name: "Buy and Rent",
                  values: rentalInfo.map((info) => `${info.day} Rent`),
                },
              ],
              images: images,
            },
          },
        });

        // If the product is added to Shopify successfully
        if (shopifyProductResponse.status === 201) {
          const productLink = `${storeUrl}products/${shopifyProductResponse.data.response.product.handle}`;
            setCreatedProductLink(productLink);
            if(communityIds.length > 0){
              createPostInMultipleCommunities({
                title: product.name,
                content: product.description,
                communityIds: communityIds,  
                buyNowUrl: productLink,
                sellerEmail: sellerEmail,
                images: [
                {
                  url: image[0],
                  description: product.description,
                },
              ], 
              });
            }
          
          setProduct(product);
          let reference_id = shopifyProductResponse.data.response.product.id;
          const options = {
            method: "POST",
            url: `${apiEndpoint}/product/addproduct`,
            headers: {
              Authorization: `${localStorage.getItem("us_st_d")}`,
            },
            data: {
              ...product,
              reference_id: reference_id,
              rentVarient: variants,
            },
          };
          const data = await axios(options);
          // console.log(data);
          if (data.status === 200) {
            setIsProductCreated(true);
            setTimeout(()=>{
              setIsProductCreated(false);
              setLinkPopup(true);
              history.push("/catalog");
            },5 * 60 * 1000)
            setProduct(product);
            toast.success("New product created successfully in store!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            // Handle error adding product to Shopify
            console.error("Error adding product:", shopifyProductResponse);
            toast.error("Error creating product!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
        setLoading(false);
      } catch (error) {
        console.log("error__",error);
        setLoading(false);

        toast.error("404 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      errorEmptyAlert();
    }
  };

  const additionalDetails = (
    <div>
      <div className="mb-3 row">
        <label
          htmlFor=""
          className="genericlabel col-md-3 col-sm-12  col-form-label"
        >
          Tags:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="tags"
            value={product.tags}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter Search Tags"
            readOnly={readOnly}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Description:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <input
            type="text"
            className="form-control-plaintext"
            style={{ borderBottom: "1px solid #262626" }}
            id=""
            name="description"
            value={product.description}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
            placeholder="Enter product description"
            readOnly={readOnly}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Product Returnable:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="returnable"
            value={product.returnable}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value={""}>Product Returnable</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Product return Window:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="return_window"
            value={product.return_window}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value="">Select return window</option>
            {returnWindowLength.map((item, index) => (
              <option value={`P${item}D`} key={index}>
                {item === 1 ? `${item}Day` : `${item}Days`}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Product Cancellable:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="cancelable"
            value={product.cancelable}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value={""}>Product Cancellable</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Return Pickup Available:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="seller_pickup_return"
            value={product.seller_pickup_return}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value={""}>Return Pickup Available</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          Product Replaceable:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="replaceable"
            value={product.replaceable}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value={""}>Product Replaceable</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-md-3 col-sm-12  col-form-label">
          COD:
        </label>
        <div className="col-md-8 col-sm-12 ">
          <select
            name="cod"
            value={product.cod}
            onChange={(e) =>
              setProduct({ ...product, [e.target.name]: e.target.value })
            }
          >
            <option value={""}>Cash on delivery</option>
            <option value={"true"}>True</option>
            <option value={"false"}>False</option>
          </select>
        </div>
      </div>
    </div>
  );

  function Showmenu() {
    setshowMenu(true);
    history.push("/sidebar");
  }

  const getProductDetails = async (productId) => {
    setIsLoading(true);
    setMsgOpen(false);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/getProductDetails/${productId}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        // console.log("data.response",data.response);
        if(data.response){
          setAllVariants(data.response.product);
          setAllMetafields(data.response.metafields);
          const variants = data.response.product.variants;
          
          let newQuantity = product.quantity;
          if(variants){
            variants.forEach((variant) => {
              const title = variant.title;
              if ( title === "1 Day Rent" || title === "2 Day Rent" || title === "3 Day Rent" || title === "4 Day Rent" || title === "5 Day Rent" || title === "6 Day Rent" || title === "Weekly Rent" ) {
                
              } else {
                newQuantity = variant.inventory_quantity;
              }
            });
        }
        setProduct((prevData) => ({
          ...prevData,
          quantity: newQuantity,
        }));
        setIsLoading(false);
        setMsgOpen(true);
        } 
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  
  const updateInventoryQty = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/updateInventoryQty`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data:items,
      });
      if (data.status === 200) {
        // console.log(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  useEffect(() => {
    if(product.reference_id){
      getProductDetails(product.reference_id);
    }
  }, [product.reference_id]);

  function formatPrice(price) {
    if (price.endsWith(".00")) {
      return parseInt(price);
    } else {
      return parseFloat(price);
    }
  }
  useEffect(() => {
    if (allVariants && allVariants.variants) {
      const updatedRentalInfo = rentalInfo.map(option => {
        const variant = allVariants.variants.find(variant => variant.title === option.day + " Rent");
        
        if (variant) {
          return { ...option, price: formatPrice(variant.price), quantity: variant.inventory_quantity.toString() };
        }
        return option;
      });
      setRentalInfo(updatedRentalInfo);
    }
    const productHandle = allVariants.handle
    const url = `${storeUrl}/products/${productHandle}`;
    setProductUrl(url);
  }, [allVariants]);
  // console.log("ProductUrl",productUrl);

  const handleCopyLink = () => {
    if (productUrl) {
      navigator.clipboard.writeText(productUrl).then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); 
      });
    }
  };
  const copyProductLink = () => {
    if (createdProductLink) {
      navigator.clipboard.writeText(createdProductLink).then(() => {
        setLinkCopied(true);
        setTimeout(() => {
          setLinkCopied(false);
        }, 120000); 
      });
    }
  };

  useEffect(() => {
    if (allVariants && allVariants.variants && rentalInfo) {
      const inventoryItems = allVariants.variants.map(variant => {
        const rentalItem = rentalInfo.find(info => info.title === variant.title);
        const availableQuantity = rentalItem ? rentalItem.quantity : variant.inventory_quantity;
        return {
          inventory_item_id: variant.inventory_item_id,
          available: (variant.title === 'Buy' || variant.title === 'Default Title') ? product.quantity.toString() : availableQuantity
        };
      });
      setItems(inventoryItems);
      if(product.rent === 'no'){
        setItems([{
          inventory_item_id: allVariants.variants[0].inventory_item_id,
          available: product.quantity.toString() 
        }]);
      }
      const totalRentOnlyQty = allVariants.variants
      .filter(variant => variant.title !== 'Buy' && variant.title !== 'Default Title')
      .reduce((total, variant) => total + variant.inventory_quantity, 0);

      setRentOnlyQty(totalRentOnlyQty);
    }
  }, [allVariants, rentalInfo,product.quantity,product.rent]);

  useEffect(() => {
    if (!hasMetafieldsProcessed && Array.isArray(allMetafields) && allMetafields.length > 0) {
      const existingMetafields = allMetafields;
      const pickupMetafield = existingMetafields.find(field => field.key === "pickup_location");
      const dropOffMetafield = existingMetafields.find(field => field.key === "drop_off_location");
      const publicPlaceMetafield = existingMetafields.find(field => field.key === "meet_public_place");
      
      const selectedShippingOptions = [];
      if (pickupMetafield) selectedShippingOptions.push('pickup');
      if (dropOffMetafield) selectedShippingOptions.push('dropoff');
      if (publicPlaceMetafield) selectedShippingOptions.push('meetup');

      const parsedPublicPlaces = publicPlaceMetafield?.value
      ? JSON.parse(publicPlaceMetafield.value)
      : [];
      
      if (pickupMetafield) {
        setProduct(prevData => ({
          ...prevData,
          pickup_location:pickupMetafield.value,
        }))
      }
      if (dropOffMetafield) {
        setProduct(prevData => ({
          ...prevData,
          drop_off_location:dropOffMetafield.value,
        }))
      }
      if (publicPlaceMetafield) {
        setProduct(prevData => ({
          ...prevData,
          // public_place:publicPlaceMetafield.value,
          public_places:parsedPublicPlaces,
        }))
      }
      setProduct(prevData => ({
        ...prevData,
        pickup_enable: !!pickupMetafield,  
        drop_off_enable: !!dropOffMetafield,  
        // public_place_enable: !!publicPlaceMetafield,  
        public_place_enable: parsedPublicPlaces.length > 0,  
        selected_shipping: selectedShippingOptions, 
      }));
      setHasMetafieldsProcessed(true);
    }
    
  }, [allMetafields,hasMetafieldsProcessed]);

  useEffect(() => {
    if(!product.pickup_enable && !product.drop_off_enable && !product.public_place_enable){
      setProduct((prev) => ({
        ...prev,
        selected_shipping: ["default_shipping"],
      }));
    }
  }, [ product.pickup_enable, product.drop_off_enable, product.public_place_enable,]);

  async function updateHandler() {
    const {
      selling_price,
      returnable,
      cancelable,
      return_window,
      cod,
      replaceable,
      seller_pickup_return,
      image,
      rent,
      rentQuantity,
      rentPrice,
      privacy_status,
      pickup_enable, 
      drop_off_enable, 
      public_place_enable,
      selected_shipping,
      category_id,
    } = product;
    const productImages = image;
    if ((category_id === "" || !category_id)) {
      toast.error("Please select category !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      return;
    }
    if ((rent === "yes" || rent === "rentonly") && !isRentalInfoFilled()) {
      toast.error("Please fill atleast 2 rent price and rent quantity!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      return;
    }
    const images = productImages.map((src) => ({ src }));
    let updatedTags = product.tags ? product.tags.split(",") : [];
    if (privacy_status && !updatedTags.includes(privacy_status)) {
      updatedTags.push(privacy_status);
    }
    if (!updatedTags.includes("multi_vendor")) {
      updatedTags.push("multi_vendor");
    }
    if (rent === "yes" || rent === "rentonly") {
      if (!updatedTags.includes("rental")) {
        updatedTags.push("rental");
      }
    } else {
      updatedTags = updatedTags.filter((tag) => tag !== "rental");
    }
    updatedTags = updatedTags.join(",");

    try {
      let variants;
      if (rent === "yes") {
        variants = createVariants();
      } else if (rent === "rentonly") {
        variants = createVariants1();
      } else {
        const singleVariant = allVariants.variants[0];
        variants = [
          {
            id: singleVariant.id,
            price: product.selling_price,
            sku: product.sku,
            inventory_management: "shopify",
            inventory_quantity: product.quantity,
            compare_at_price: product.mrp,
            tags: product.tags,
            option1: "Default Title",
            title: "Default Title",
          },
        ];
      }
      updateInventoryQty();
      setUpLoading(true);
      
      const existingMetafields = Array.isArray(allMetafields) ? allMetafields : [];
      // const metafieldsToDelete = existingMetafields.filter(field => 
      //     (product[field.key] === "" || product[field.key] === undefined)
      // );

      const updatedMetafields = [
          {
              id: existingMetafields.find(field => field.key === "drop_off_location")?.id,
              namespace: "custom",
              key: "drop_off_location",
              value: selected_shipping.includes('dropoff') && drop_off_enable ? product.drop_off_location : "",
              type: "multi_line_text_field",
          },
          {
              id: existingMetafields.find(field => field.key === "pickup_location")?.id,
              namespace: "custom",
              key: "pickup_location",
              value: selected_shipping.includes('pickup') && pickup_enable ? product.pickup_location : "",
              type: "multi_line_text_field",
          },
          {
              id: existingMetafields.find(field => field.key === "meet_public_place")?.id,
              namespace: "custom",
              key: "meet_public_place",
              value: selected_shipping.includes("meetup") && public_place_enable ? JSON.stringify(product.public_places.filter(place => place.trim() !== "")) : "",  
              type: "list.single_line_text_field",
          },
      ].filter(metafield => metafield.value !== undefined && metafield.value !== "");

      const metafieldsToDelete = existingMetafields.filter(existingField => 
        !updatedMetafields.some(updatedField => updatedField.key === existingField.key && updatedField.value !== "")
      );
      // console.log("metafieldsToDelete", metafieldsToDelete);
      // console.log("metafieldsToDelete",metafieldsToDelete);

      if (metafieldsToDelete.length > 0) {
        for (const metafield of metafieldsToDelete) {
          await axios({
            method: "delete",
            url: `${apiEndpoint}/product/deleteProductMetafieldInShopifystore`,
            data: {
              productId: product.reference_id,
              metafieldId: metafield.id,
            },
          });
        }
      }
      const data = {
        id: product.reference_id,
        product: {
          title: product.name,
          body_html: product.description,
          product_type: product.brand,
          status: "active",
          tags: updatedTags,
          published_at: new Date(),
          variants: variants,
          metafields: updatedMetafields,
          options: [
            {
              name: "Buy and Rent",
              values: rentalInfo.map((info) => `${info.day} Rent`),
            },
          ],
          images: images,
        },
      };
      if (catName) {
        data.product.product_type = catName;
      }
      const shopifyProductResponse = await axios({
        method: "POST",
        url: `${apiEndpoint}/product/updateproductinshopifystore`, // Shopify API endpoint to add product
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Access-Token": "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",
        },
        data: data,
      });
      // If the product is updated to Shopify successfully
      if (shopifyProductResponse.status === 201) {
        const productLink = `${storeUrl}/products/${shopifyProductResponse.data.response.product.handle}`
          if(communityIds.length > 0){
            createPostInMultipleCommunities({
              title: product.name,
              content: product.description,
              communityIds: communityIds,  
              buyNowUrl: productLink,
              sellerEmail: sellerEmail,
              images: [
                {
                  url: image[0],
                  description: product.description,
                },
              ], 
            });
          }
        setProduct(product);
        const data = await axios({
          url: `${apiEndpoint}/product/updateproducts`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            ...product,
            id: id,
            rentVarient: variants,
          },
        });
        setUpLoading(false);
        if (data.status === 200) {
          history.push("/catalog");
          toast.success("Product updated successfully !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("404 error ! 3", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("404 error ! 2", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setUpLoading(false);
      console.log("Error",err)
      toast.error("404 error ! 1", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const linkName = read
    ? `Edit Additional Details >`
    : `Edit Additional Details >`;

  const renderRentalInfoFields = () => {
    // console.log(rentalInfo);
    return rentalInfo.map(
      (info, index) =>
        info.option1 !== "Buy" &&
        info.title !== "Buy" && (
          <div
            key={index}
            className="rental-info-fields mb-3 row d-flex align-items-end"
          >
            <div className="d-block col-md-3">
              {info.day === "" && (
                <span className="text-danger tiny_size">
                  This field is required!
                </span>
              )}

              <input
                type="text"
                value={info.day}
                onChange={(e) =>
                  handleRentalInfoChange(index, "day", e.target.value)
                }
                placeholder={info.day}
                className="form-control"
                disabled={true}
              />
            </div>
            <div className="d-block col-md-3">
              {/*{info.price === "" && (
                <span className="text-danger tiny_size">
                  This field is required!
                </span>
              )}*/}
              <input
                type="number"
                value={info.price}
                onChange={(e) =>
                  handleRentalInfoChange(index, "price", e.target.value)
                }
                placeholder="Price"
                className="form-control"
              />
            </div>
            <div className="d-block col-md-6">
             {/* {info.quantity === "" && (
                <span className="text-danger tiny_size">
                  This field is required!
                </span>
              )}*/}

              <input
                type="number"
                value={info.quantity}
                onChange={(e) =>
                  handleRentalInfoChange(index, "quantity", e.target.value)
                }
                placeholder="Available Quantity"
                className="form-control"
              />
            </div>
            {/*<div className="d-block col-md-3">
           {rentalInfo.length > 1 && (
             <button onClick={() => removeRentalInfo(index)} className="btn-danger"><RemoveIcon /> </button>
           )}
          </div>*/}
          </div>
        )
    );
  };

  const renderAddMoreButton = () => {
    return (
      <button onClick={addMoreRentalInfo} className="btn-primary">
        <AddIcon />
      </button>
    );
  };
  const handleRentalInfoChange = (index, field, value) => {
    const updatedRentalInfo = [...rentalInfo];
    updatedRentalInfo[index][field] = value;
    setRentalInfo(updatedRentalInfo);
  };

  // Function to add more rental information fields
  const addMoreRentalInfo = () => {
    setRentalInfo([...rentalInfo, { day: "", price: "", quantity: "" }]);
  };
  const removeRentalInfo = (indexToRemove) => {
    const updatedRentalInfo = rentalInfo.filter(
      (_, index) => index !== indexToRemove
    );
    setRentalInfo(updatedRentalInfo);
  };

  const handleSelectGroup = (community) => {
    if (selectedGroups.some(selected => selected._id === community._id)) {
      setSelectedGroups(selectedGroups.filter(selected => selected._id !== community._id));
    } else {
      setSelectedGroups([...selectedGroups, community]);
    }
  };

  const handleRemoveGroup = (community) => {
    setSelectedGroups(selectedGroups.filter(selected => selected._id !== community._id));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };
  
  useEffect(() => {
    const isDesktop = window.innerWidth > 768;
  
    if (isDesktop) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  
    return () => {
      if (isDesktop) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, []);
  

  useEffect(() => {
    const handleResize = () => {
      const isDesktop = window.innerWidth > 768;
      if (isDesktop) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    const getSellerCommunityList = async () => {
      try {
        const { data } = await axios({
          url: `${communityUrl}/community/seller-communities`,
          method: 'POST',
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`
          },
          data: {
            sellerEmail: sellerEmail,
          },
        });
        // console.log("response__",data);
        if (data.success === true) {
          if(data?.communities){
            const sellerCommunityList = data?.communities.filter(community => 
              community.communityType === "public" || 
              (community.communityType === "private" && community.isApproved === true) ||
              community.author.useremail === sellerEmail
            );
            // console.log("sellerCommunityList", sellerCommunityList);
            setSellerCommunities(sellerCommunityList);
          }
        } else {
          console.error("Failed to get the seller's community list");
        }
      } catch (error) {
        console.error("Error in fetching seller's community list", error);
      }
    };
    getSellerCommunityList();
  },[sellerEmail])
  // console.log("sellerCommunities",sellerCommunities);
  
  useEffect(()=>{
    if(selectedGroups.length > 0){
      const communityIds = selectedGroups.map(group => group._id);
      setCommunityIds(communityIds);
    }
  },[selectedGroups]);
  // console.log("communityIds__",communityIds);

  const createPostInMultipleCommunities = async (postDetails) => {
    try {
      const { data } = await axios({
        url: `${communityUrl}/post/create-multiple-post`,
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {
          title: postDetails.title,
          content: postDetails.content,
          communityIds: postDetails.communityIds,  
          buyNowUrl: postDetails.buyNowUrl,
          sellerEmail: postDetails.sellerEmail,
          images: postDetails.images, 
        },
      });
    } catch (error) {
      console.error("Error creating post in multiple communities", error);
    }
  };

  const commissionPercentage = 7; 

  const calculateFinalPrice = (sellingPrice) => {
    const commission = (commissionPercentage / 100) * sellingPrice;
    return sellingPrice - commission;
  };

  useEffect(() => {
    if (product.selling_price > 0) {
      const finalPrice = calculateFinalPrice(product.selling_price);
      setProduct((prevProduct) => ({
        ...prevProduct,
        finalSellingPrice: finalPrice.toFixed(2), 
      }));
      setFinalSellingPrice(finalPrice.toFixed(2));
    } else {
      setFinalSellingPrice(0);
      setProduct((prevProduct) => ({
        ...prevProduct,
        finalSellingPrice: "", 
      }));
    }
  }, [product.selling_price]);

  const handleTooltip = (type) => {
    setActiveTooltip(type);
  };

  const hideTooltip = () => {
    setActiveTooltip(null);
  };


  const shippingOptions = [
    { label: "Pickup", value: "pickup", stateKey: "pickup_enable" },
    { label: "Dropoff", value: "dropoff", stateKey: "drop_off_enable" },
    { label: "Meetup", value: "meetup", stateKey: "public_place_enable" },
    { label: "Default Shipping", value: "default_shipping", stateKey: "default_shipping_enable" },
  ];

  const handleShippingChange = (e) => {
    const { value, checked } = e.target;
    const selectedOption = shippingOptions.find(option => option.value === value);

    setProduct((prev) => ({
      ...prev,
      selected_shipping: checked
        ? [...prev.selected_shipping, value] 
        : prev.selected_shipping.filter((shipping) => shipping !== value), 
      ...(selectedOption?.stateKey && { [selectedOption.stateKey]: checked }) 
    }));
  };


  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(event.target)) {
  //       setOpenDropdown(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  const handleMainClick = (index, mainHandle) => {
    setOpenMainCategory(openMainCategory === index ? mainCategoryIndex : index);
    setSelectedCategory({
      main: mainHandle,
      sub_collection: null,
    });
    setOpenSubCategory(null);
  };

  const handleSubClick = (subCategory) => {
    const collection = categoryList.find((c) => c.handle.trim() === subCategory.handle.trim());
    const getThirdLevelCollections = categoryList.filter((collection) => {
      if (!collection.metafields || collection.metafields.length === 0) {
        return false;
      }
      const metafieldMap = collection.metafields.reduce((acc, metafield) => {
        acc[metafield.key] = metafield.value;
        return acc;
      }, {});

      return (
        metafieldMap.main === selectedCategory.main &&
        metafieldMap.sub_collection === subCategory.handle
      );
    });

    if (getThirdLevelCollections.length === 0 && collection) {
      setProduct({
        ...product,
        category_id: collection.id, 
      });
      setSelectedCollection({
        handle: subCategory.handle, 
        title: subCategory.name, 
      });
      // setOpenMainCategory(null);
      setFilteredCollections([]);
      setOpenDropdown(false);
    } else {
      setFilteredCollections(getThirdLevelCollections);
    }

    setSelectedCategory((prevState) => ({
      ...prevState,
      sub_collection: subCategory.handle,
    }));
    setOpenSubCategory(openSubCategory === subCategory.handle ? null : subCategory.handle);
  };

  const handleThirdClick = (collection) => {
    setSelectedCollection({
      handle: collection.handle, 
      title: collection.title, 
    });
    setProduct({
      ...product,
      category_id: collection.id,
    })
    // setOpenMainCategory(null);
    setFilteredCollections([]);
    setOpenDropdown(false); 
  };

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };
  
  useEffect(()=>{
    if(product?.category_id && id){
      const collection = categoryList.find((c) => parseInt(c.id) === parseInt(product?.category_id));
        setInitialCollection(collection?.title);
        setSelectedCollection({
          handle: collection?.handle, 
          title: collection?.title, 
        });
      } else {
      setInitialCollection("");
    }
  },[categoryList,product?.category_id,id]);

const handleSearchChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query);
  setSelectedCollection({
    ...selectedCollection,
    title: query, 
  });
  setInitialCollection(e.target.value);
  setOpenMainCategory(null);
  
  if (query.trim() !== "") {
    const results = categoryList.filter((category) =>
      category.title.toLowerCase().includes(query.toLowerCase())
  );
  setSearchResults(results);
  setOpenDropdown(true);
} else {
  setProduct({ ...product, category_id: "", });
    setSearchResults([]);
  }
};

const handleSearchSelect = (result) => {
  setSelectedCollection({
    handle: result.handle, 
    title: result.title, 
  });
  setProduct({
    ...product,
    category_id: result.id,
  })
  setSearchQuery("");
  setSearchResults([]);
  setOpenDropdown(false);
};

  useEffect(() => {
    if (!selectedCollection?.handle) return;
    const categoryItem = categoryList.find(item => item.handle === selectedCollection.handle);
  
    if (categoryItem) {
      const metafields = categoryItem.metafields || null;
  
      if (metafields && metafields.length > 0) {
        const mainHandle = metafields.find(field => field.key === "main")?.value;
        // const subHandle = metafields.find(field => field.key === "sub_collection")?.value;
  
        if (mainHandle) {
          const mainCategoryIndex = main_collection.findIndex(item => item.handle === mainHandle);
          setMainCategoryIndex(mainCategoryIndex);
          return;
        }
      }

      let foundIndex = null;
      main_collection.forEach((mainCategory, index) => {
        const isFoundInSubCollections = mainCategory.sub_collections.some(
          sub => sub.handle.trim() === categoryItem.handle.trim()
        );
        if (isFoundInSubCollections) {
          foundIndex = index;
        }
      });

      if (foundIndex === null) {
        foundIndex = main_collection.findIndex(item => item.handle.trim() === categoryItem.handle.trim());
      }
  
      setMainCategoryIndex(foundIndex);
      setOpenMainCategory(foundIndex);
    }
  }, [selectedCollection.handle, categoryList,searchQuery]);

  return (
    <>
    <ThemeMaker>
      <div className="row w-100 m-0 mt-1">
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">
                {pageNamem ? "Create product" : "Update product"}
              </h6>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
                <CircularProgress />
              </div>
            ) : (
              <div className="card-body">
              <p>
                <b>Disclaimer:</b> Nike, Jordan, Adidas, vape, cannabis,
                marijuana, CBD, THC, weed, firearm, gun, bullet, dope, pistol,
                cleaning supplies, cleaning equipment, holsters, riffle,
                shotguns, Air pistols, sawa, range finders, rifle parts, etc. —
                the mentioned products are excluded from the listing.
              </p>

              <div className="row bg-white">
                <div className="col-md-12 pl-4 pr-4">
                  <div className="row pt-2">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="genericlabel">Name :</label>
                        <span className="text-danger"> * </span>
                        {productErr?.name ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            {productErr.name}{" "}
                          </span>
                        ) : null}
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          name="name"
                          value={product.name}
                          onChange={handleChange}
                          onBlur={(e) => errorHandler(e)}
                          placeholder="Enter Product Name"
                          readOnly={readOnly}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="dropdown col-md-4 col-sm-12" ref={categoryDropdownRef}>
                      <label className="genericlabel">Category</label>
                      <span className="text-danger"> * </span>
                      <div className="collection-container position-relative"> 
                      <input
                          type="text"
                          className="form-control"
                          name="category_id"
                          value={initialCollection ? initialCollection : selectedCollection.title}
                          onChange={handleSearchChange}
                          onBlur={(e) => errorHandler(e)}
                          placeholder="Select or Search Category"
                          readOnly={readOnly}
                          disabled={loading}
                          onClick={toggleDropdown}
                        />
                        {selectedCollection.title && (
                          <span
                            type="button"
                            className="btn position-absolute ml-2"
                            style={{top:0,right:0}}
                            onClick={() => {
                              setProduct({ ...product, category_id: "" });
                              setSelectedCollection({
                                ...selectedCollection,title:""
                              });
                              setMainCategoryIndex(null);
                              setOpenDropdown(false);
                              setOpenMainCategory(null);
                              setSearchQuery("");
                            }}
                          >
                            X
                          </span>
                        )}
                        {openDropdown && searchQuery.trim() === "" && (
                          <div className="collection-menu">
                            {main_collection.map((mainCategory, mainIndex) => (
                              <div key={mainCategory.handle} className={`main-collection`}>
                                <div
                                  className={`main-collection-item d-flex align-items-center justify-content-start ${mainCategory.handle} ${mainCategoryIndex === mainIndex ? 'highlighted' : ''}`}
                                  onClick={() => handleMainClick(mainIndex, mainCategory.handle)}
                                  style={{borderBottom: "1px solid #eee"}}
                                >
                                  <span className="arrow">{openMainCategory === mainIndex ? <RemoveIcon fontSize="small"/> : <AddIcon fontSize="small"/>}</span>
                                  {mainCategory.displayName}
                                </div>
                                {openMainCategory === mainIndex && (
                                  <div className="sub-collection">
                                    {mainCategory.sub_collections.map((subCategory) => (
                                      <div
                                        key={subCategory.handle}
                                        className={`sub-collection-item ${openSubCategory === subCategory.handle ? "minusHover" : "plusHover"} ${subCategory.handle === selectedCollection.handle ? 'highlighted' : ''}`}
                                        style={{marginLeft:"10%",borderBottom: "1px solid #eee"}}
                                        onClick={() => handleSubClick(subCategory)}
                                      >
                                        <div className="w-100 d-flex align-items-center justify-content-start" style={{borderBottom: openSubCategory === subCategory.handle ? "1px solid #eee" : "none"}}>
                                        <span className="arrow">{openSubCategory === subCategory.handle ? <RemoveIcon fontSize="small"/> : <AddIcon fontSize="small"/>}</span>
                                        <span>{subCategory.name}</span>
                                        </div>
                                        {openSubCategory === subCategory.handle && (
                                          filteredCollections && filteredCollections.length > 0 && (
                                          <div className="collection-list"
                                          style={{marginLeft:"10%"}}>
                                            {filteredCollections.map((collection) => (
                                              <div
                                                key={collection.handle}
                                                className={`collection-list-item ${collection.handle === selectedCollection.handle ? 'highlighted' : ''}`}
                                                style={{borderBottom: "1px solid #eee"}}
                                                onClick={() => handleThirdClick(collection)}
                                              >
                                                <div className="w-100 d-flex align-items-center justify-content-start">
                                                  <span className="arrow">{filteredCollections && filteredCollections.length > 0 ? <AddIcon fontSize="small"/> : <RemoveIcon fontSize="small"/>}</span><span>{collection.title}</span>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                          )
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                        {openDropdown && searchQuery.trim() !== "" && (
                          <div className="search-result-menu collection-menu">
                            {searchResults.length > 0 ? (
                              searchResults.map((result) => (
                                <div
                                  key={result.id}
                                  className={`search-result-item collection-list-item ${result.handle === selectedCollection.handle ? 'highlighted' : ''}`}
                                  onClick={() => handleSearchSelect(result)}
                                  style={{ borderBottom: "1px solid #eee", padding: "8px" }}
                                >
                                  {result.title}
                                </div>
                              ))
                            ) : (
                              <div className="no-results" style={{ padding: "8px", color: "gray" }}>
                                No results found
                              </div>
                            )}
                          </div>
                        )}
                        
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="genericlabel">Quantity</label>
                        <span className="text-danger"> * </span>
                        {productErr?.quantity ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          onKeyPress={preventMinus}
                          name="quantity"
                          value={(params.id && product.rent === 'rentonly') ? rentOnlyQty : product.quantity}
                          // value={product.quantity}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              [e.target.name]: e.target.value,
                            })
                          }
                          placeholder="Enter Quantity"
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readOnly}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="genericlabel"> Stock Keeping Unit: </label>
                        <span className="text-danger"> * </span>
                        <span
                          className="d-inline-block cursor-pointer"
                          onMouseEnter={() => handleTooltip('sku')}
                          onMouseLeave={hideTooltip}
                          style={{ position: "relative" }}
                        >
                          <InfoIcon color="primary" fontSize="small" />
                          {(activeTooltip === 'sku') && (
                            <div
                              className="custom-tooltip"
                              style={{
                                position: "absolute",
                                bottom: "100%",
                                left: "100px",
                                transform: "translateX(-50%)",
                                backgroundColor: "#333",
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                zIndex: 1,
                                whiteSpace: "nowrap",
                              }}
                            >
                              Use a Unique, Consistent Format. Avoid Special Characters. Example: TSHIRT098123
                            </div>
                          )}
                        </span>
                        {productErr?.sku ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          name="sku"
                          value={product.sku}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              [e.target.name]: e.target.value,
                            })
                          }
                          placeholder="Enter SKU"
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readOnly}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="genericlabel">Brand :</label>
                        <span className="text-danger"> * </span>
                        {productErr?.brand &&
                          <span className="text-danger tiny_size">
                            {productErr?.brand}
                          </span>
                        }
                        <input
                        type="text"
                        className="form-control"
                        name="brand"
                        placeholder="Enter Brand Name"
                        value={product.brand}
                        onChange={handleChange}
                        onBlur={(e) => errorHandler(e)}
                        disabled={loading}
                      />
                        {/* <select
                          className="form-control"
                          value={product.brand}
                          name="brand"
                          id=""
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              [e.target.name]: e.target.value,
                            })
                          }
                          onBlur={(e) => errorHandler(e)}
                          disabled={readOnly}
                        >
                          <option value="">Select Brand</option>
                          {brandlist.map((item) => (
                            <option value={item.brand_name} key={item.id}>
                              {item.brand_name}
                            </option>
                          ))}
                        </select> */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="genericlabel">
                          Selling Price in USD :{" "}
                        </label>
                        {/* <br /> */}
                        {/*<small> &nbsp; Tax included</small>
                    <input
                      type="checkbox"
                      name="tax_included"
                      style={{ marginLeft: "10px" }}
                      onChange={(e) => priceHandler(e)}
                      checked={product?.tax_included}
                      disabled={readOnly}
                      />*/}
                        <span className="text-danger"> * </span>
                        {productErr?.selling_price ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                        <input
                          type="number"
                          className="form-control"
                          min={0}
                          name="selling_price"
                          placeholder="Selling Price"
                          value={product.selling_price}
                          onChange={(e) => priceHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="genericlabel">
                          Original Price in USD :
                        </label>
                        <span className="text-danger"> * </span>
                        {productErr?.mrp ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                        <input
                          type="number"
                          className="form-control"
                          onKeyPress={preventMinus}
                          min={0}
                          name="mrp"
                          placeholder="Enter Original Price"
                          value={product.mrp ? product.mrp : product.price}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              [e.target.name]: e.target.value,
                            })
                          }
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readOnly}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="genericlabel">
                          Earnings in USD (When Sold) :
                        </label>
                        {/* <span className="text-danger"> * </span>
                        {productErr?.finalSellingPrice ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null} */}
                        <input
                          type="number"
                          step="any"
                          className="form-control"
                          onKeyPress={preventMinus}
                          min={0}
                          name="finalSellingPrice"
                          placeholder="Your Earnings When Sold"
                          value={finalSellingPrice}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              [e.target.name]: e.target.value,
                            })
                          }
                          onBlur={(e) => errorHandler(e)}
                          readOnly={readOnly}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="genericlabel">Tag :</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          name="tags"
                          value={product.tags}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              [e.target.name]: e.target.value,
                            })
                          }
                          placeholder="Enter Search Tags"
                          readOnly={readOnly}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="col-md-12 px-0">
                            <div className="form-group">
                              <label className="genericlabel">Rental Product :</label>
                              <span className="text-danger"> * </span>
                              {productErr?.rent ? (
                                <span className="text-danger tiny_size">
                                  {" "}
                                  This field is required !{" "}
                                </span>
                              ) : null}

                              <div className="d-flex py-2">
                                <label
                                  htmlFor="rentYes"
                                  className="d-flex"
                                  style={{ cursor:"pointer", marginBottom: "0px" }}
                                >
                                  <input
                                    type="radio"
                                    id="rentYes"
                                    name="rent"
                                    value="yes"
                                    className="mr-1"
                                    checked={product.rent === "yes"}
                                    onChange={(e) =>
                                      setProduct({
                                        ...product,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                    onBlur={(e) => errorHandler(e)}
                                  />
                                  Rent and Sell
                                </label>
                                <label
                                  htmlFor="rentNo"
                                  className="d-flex"
                                  style={{ cursor:"pointer", marginBottom: "0px", marginLeft: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    id="rentNo"
                                    name="rent"
                                    value="no"
                                    className="mr-1"
                                    checked={product.rent === "no"}
                                    onChange={(e) =>
                                      setProduct({
                                        ...product,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                    onBlur={(e) => errorHandler(e)}
                                  />
                                  Sell Only
                                </label>
                                <label
                                  htmlFor="rentOnly"
                                  className="d-flex"
                                  style={{ cursor:"pointer", marginBottom: "0px", marginLeft: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    id="rentOnly"
                                    name="rent"
                                    value="rentonly"
                                    className="mr-1"
                                    checked={product.rent === "rentonly"}
                                    onChange={(e) =>
                                      setProduct({
                                        ...product,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                    onBlur={(e) => errorHandler(e)}
                                  />
                                  Rent Only
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 px-0">
                            <div className="form-group">
                              {product.rent === "yes" && (
                                <>
                                  <label className="genericlabel">
                                    Rent Settings:
                                  </label>
                                  <span className="text-danger"> * </span>

                                  <div className="row">
                                    <div className="col-md-12">
                                      {renderRentalInfoFields()}
                                    </div>
                                    {/* <div className="col-md-2">
                                {renderAddMoreButton()}
                          </div>*/}
                                  </div>
                                </>
                              )}
                              {product.rent === "rentonly" && (
                                <>
                                  <label className="genericlabel">
                                    Rent Settings:
                                  </label>
                                  <span className="text-danger"> * </span>

                                  <div className="row">
                                    <div className="col-md-12">
                                      {renderRentalInfoFields()}
                                    </div>
                                    {/*<div className="col-md-2">
                                {renderAddMoreButton()}
                          </div>*/}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="col-md-12 px-0">
                            <div className="form-group">
                              <label className="genericlabel">Shipping :</label>
                              <span className="text-danger"> * </span>
                              {productErr?.selected_shipping ? (
                                <span className="text-danger tiny_size">
                                  {" "}
                                  This field is required !{" "}
                                </span>
                              ) : null}

                              <div className="d-flex py-2">
                                {shippingOptions.map((option) => (
                                  <label key={option.value} className="mr-2 d-flex align-items-center" style={{ cursor: "pointer", marginBottom: "0px" }}>
                                    <input
                                      type="checkbox"
                                      className="mr-1"
                                      value={option.value}
                                      checked={
                                        option.stateKey === "pickup_enable" ? product.pickup_enable :
                                        option.stateKey === "drop_off_enable" ? product.drop_off_enable :
                                        option.stateKey === "public_place_enable" ? product.public_place_enable :
                                        option.stateKey === "default_shipping_enable" ? product.default_shipping_enable :
                                        false 
                                      }
                                      onChange={handleShippingChange}
                                    />
                                    {option.label}
                                  </label>
                                ))}
                              </div>

                            </div>
                          </div>
                          <div className="col-md-12 px-0">
                            <div className="d-flex flex-column">
                              {(product.selected_shipping.includes('pickup') && product.pickup_enable) && (
                                <div className="form-group w-100 mb-0 d-flex align-items-center justify-content-center mb-1 flex-column flex-sm-row">
                                  <label className="genericlabel col-md-4 px-1">Pickup Location: {" "}
                                  <span className="d-inline-block cursor-pointer"
                                        onMouseEnter={() => handleTooltip('pickup')}
                                        onMouseLeave={hideTooltip}
                                        >
                                        <InfoIcon color="primary" fontSize="small" />
                                        {activeTooltip === 'pickup' && (
                                          <div
                                            className="custom-tooltip"
                                            style={{
                                              position: "absolute",
                                              bottom: "100%",
                                              left: "50%",
                                              transform: "translateX(-50%)",
                                              backgroundColor: "#333",
                                              color: "#fff",
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              fontSize: "12px",
                                              zIndex: 1,
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            Adding a pickup location helps customers know where to collect their orders, improving their overall experience.
                                          </div>
                                        )}
                                      </span>
                                  </label>
                                  <div className="d-flex align-items-center col-md-8 px-1">
                                    <input
                                      type="text"
                                      className="form-control bg-white"
                                      name="pickup_location"
                                      value={product.pickup_location}
                                      placeholder="Enter Pickup Location"
                                      onChange={(e) => setProduct({...product, pickup_location: e.target.value})}
                                    />
                                  </div>
                                </div>
                              )}
                              {(product.selected_shipping.includes('dropoff') && product.drop_off_enable) && (
                                <div className="form-group w-100 mb-0 d-flex align-items-center justify-content-center mb-1 flex-column flex-sm-row">
                                  <label className="genericlabel col-md-4 px-1">Drop-Off Location: {" "}
                                  <span className="d-inline-block cursor-pointer"
                                      onMouseEnter={() => handleTooltip('dropoff')}
                                      onMouseLeave={hideTooltip}
                                      >
                                      <InfoIcon color="primary" fontSize="small" />
                                      {activeTooltip === 'dropoff' && (
                                        <div
                                          className="custom-tooltip"
                                          style={{
                                            position: "absolute",
                                            bottom: "100%",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            backgroundColor: "#333",
                                            color: "#fff",
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            fontSize: "12px",
                                            zIndex: 1,
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Make sure to include a drop-off location to streamline the return or exchange process for your customers.
                                        </div>
                                      )}
                                    </span>
                                  </label>
                                  <div className="d-flex align-items-center col-md-8 px-1">
                                    <input
                                      type="text"
                                      className="form-control bg-white"
                                      name="drop_off_location"
                                      value={product.drop_off_location}
                                      placeholder="Enter Drop-Off Location"
                                      onChange={(e) => setProduct({...product, drop_off_location: e.target.value})}
                                    />
                                  </div>
                                </div>
                              )}
                              {(product.selected_shipping.includes('meetup') && product.public_place_enable) && (
                                <div className="form-group w-100 mb-0 d-flex align-items-center justify-content-center mb-1 mt-1 flex-column flex-sm-row">
                                  <label className="genericlabel col-md-4 px-1">
                                    Meet At Public Places: {" "}
                                    <span
                                      className="d-inline-block cursor-pointer"
                                      onMouseEnter={() => handleTooltip('meetup')}
                                      onMouseLeave={hideTooltip}
                                    >
                                      <InfoIcon color="primary" fontSize="small" />
                                      {activeTooltip === 'meetup' && (
                                        <div
                                          className="custom-tooltip"
                                          style={{
                                            position: "absolute",
                                            bottom: "100%",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            backgroundColor: "#333",
                                            color: "#fff",
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            fontSize: "12px",
                                            zIndex: 1,
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Add multiple meet-at public places for customers to choose safe locations for pickups and deliveries.
                                        </div>
                                      )}
                                    </span>
                                  </label>
                                  <div className="d-flex flex-column col-md-8 px-1">
                                    {product.public_places.map((place, index) => (
                                      <div key={index} className="d-flex align-items-center mb-2">
                                      <input
                                          type="text"
                                          className="form-control bg-white"
                                          value={place}
                                          placeholder="Enter Meet-Up Location"
                                          onChange={(e) => {
                                            const updatedPlaces = [...product.public_places];
                                            updatedPlaces[index] = e.target.value;
                                            setProduct({ ...product, public_places: updatedPlaces });
                                          }}
                                        />
                                        <button
                                          type="button"
                                          className="btn btn-danger ml-2"
                                          onClick={() => {
                                            const updatedPlaces = product.public_places.filter((_, i) => i !== index);
                                            setProduct({ ...product, public_places: updatedPlaces });
                                          }}
                                        >
                                          X
                                        </button>
                                      </div>
                                    ))}

                                    <div className="d-flex align-items-center mt-2">
                                      <input
                                        type="text"
                                        className="form-control bg-white"
                                        placeholder={`Enter Meet-Up Location`}
                                        value={newPublicPlace}
                                        onChange={(e) => setNewPublicPlace(e.target.value)}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-primary ml-2"
                                        onClick={() => {
                                          if (newPublicPlace.trim()) {
                                            setProduct({ 
                                              ...product, 
                                              public_places: [...product.public_places, newPublicPlace.trim()] 
                                            });
                                            setNewPublicPlace("");
                                          } else {
                                            toast.error("Please enter a valid location!", {
                                              position: "top-right",
                                              autoClose: 5000,
                                              hideProgressBar: false,
                                              closeOnClick: true,
                                              pauseOnHover: true,
                                              draggable: true,
                                              progress: undefined,
                                            });
                                          }
                                        }}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    

                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <label className="genericlabel">Images: </label>
                        <span className="text-danger">*</span>
                        <span
                          style={{ color: "#70bb80fc", paddingLeft: "50px" }}
                        >
                          {imgUpload ? "Please wait while uploading......." : ""}
                        </span>
                        {productErr?.image && (
                          <span className="text-danger tiny_size">
                            This field is required!
                          </span>
                        )}
                        <input
                          type="file"
                          className="form-control text-white"
                          style={{ lineHeight: "1" }}
                          accept="image/*"
                          name="image"
                          multiple 
                          onChange={(e) => productImageHandler(e)}
                          onBlur={(e) => errorHandler(e)} 
                          disabled={readOnly}
                        />
                        <p>
                          {product.image.length}{" "}
                          {product.image.length === 1 ? "file" : "files"}{" "}
                          selected
                        </p>
                      </div>
                      {/* Display uploaded images */}

                      <div
                        className="row"
                        style={{ backgroundColor: " #f3f3f3" }}
                      >
                        {product.image.map((image, index) => (
                          <div
                            key={index}
                            className="col-md-2 col-sm-12 d-flex"
                            style={{ marginBottom: "10px" }}
                          >
                            <img
                              src={image}
                              alt={`ProductImage ${index + 1}`}
                              style={{ maxWidth: "70%", height: "140px" }}
                            />
                            <button
                              onClick={() => deleteImage(index)}
                              className="btn btn-danger btn-sm "
                              style={{
                                width: "30px",
                                height: "30px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                              }}
                            >
                              x
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="genericlabel">Description :</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          name="description"
                          value={product.description}
                          onChange={(e) =>
                            setProduct({
                              ...product,
                              [e.target.name]: e.target.value,
                            })
                          }
                          placeholder="Enter product description"
                          readOnly={readOnly}
                          disabled={loading}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="genericlabel">Visibility 
                        {/* {pageNamem && <>
                        (<span style={{fontSize:"12px",color:"#3a3b45"}}>Get the product link</span><span
                            className="d-inline-block cursor-pointer"
                            title="Once the product is created, you can get its link from the Product List Tab"
                          >
                            <InfoIcon color="primary" fontSize="small" />
                          </span>)
                          </>} */}
                          </label>
                        <span className="text-danger"> * </span>
                        {productErr?.privacy_status ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                        <select
                          className="form-control"
                          value={product.privacy_status}
                          name="privacy_status"
                          id="privacy_status"
                          onChange={(e) =>
                              setProduct({
                                ...product,
                                [e.target.name]: e.target.value,
                              })
                            }
                          onBlur={(e) => errorHandler(e)}
                          disabled={loading}
                        >
                          <option value="">Select Visibility</option>
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div ref={dropdownRef} className="position-relative groupsDropdownSection">
                        <label className="genericlabel">Select Groups to Post This Product</label>
                        <div
                          className="d-flex align-items-center justify-content-between form-control"
                          onClick={() => setShowDropdown(!showDropdown)}
                        >
                          <span className='capitalize'>{selectedGroups.length > 0 ? 'Selected Groups' : 'Select'}</span>
                          <ExpandMoreIcon />
                        </div>
                        {showDropdown && (
                          <div className="position-absolute mt-1 top-0 start-0 end-0 rounded-xl bg-white shadow-[0px_4px_44px_0px_#00000040] z-10 p-2 border groupsDropdown">
                            <ul className="max-h-60 overflow-auto py-2">
                              {sellerCommunities.length > 0 ? (
                                sellerCommunities.map((community) => (
                                  <li
                                    key={community._id}
                                    onClick={() => handleSelectGroup(community)}
                                    className="px-2 py-[6px] cursor-pointer text-[#0E081E] font-semibold flex justify-between items-start text-sm"
                                  >
                                    <div className="d-flex align-items-center w-[80%] selectGroup">
                                      <input
                                        type="checkbox"
                                        checked={selectedGroups.some(selected => selected._id === community._id)}
                                        readOnly
                                        className="mr-2 mt-[3px]"
                                      />
                                      <span className="">{community.title}</span>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <li className="p-2 text-gray-500">No groups found</li>
                              )}
                            </ul>
                            {sellerCommunities.length > 0 &&
                            <button className="btn btn-primary" onClick={() => setShowDropdown(!showDropdown)}>Select</button>}
                          </div>
                        )}

                        {selectedGroups.length > 0 && (
                          <div className="mt-2 px-3 mb-3 font-nunito font-extrabold">
                            {selectedGroups.map((community, index) => (
                              <div key={community._id} className="d-flex align-items-center justify-content-between items-start text-sm rounded-md mb-2 capitalize">
                                <div className='d-flex align-items-start text-dark w-[90%]'>
                                  <span className='selectedIndex mr-2'>{index + 1}.</span>
                                  <span>{community.title}</span>
                                </div>
                                <span className="removeGroup" style={{ cursor: "pointer" }}>
                                  <CloseIcon style={{fontSize:"19px"}} onClick={() => handleRemoveGroup(community)} />
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={`d-flex align-items-center ${pageNamem ? 'justify-content-end' : 'justify-content-between'} mt-2 flex-wrap flex-sm-nowrap`}>
                        {!pageNamem && 
                        <div className={`${copied ? 'text-success' : 'text-primary' } mt-4`} style={{cursor: "pointer", display: "flex", alignItems: "center",padding:"0px 4px", border:"1px solid",borderRadius:"0.35rem" }} onClick={handleCopyLink}>
                        {copied ? (
                          <>
                            <IoIosCheckmark
                              className="m-1 btn btn-success"
                              style={{
                                height: "1.8em",
                                borderRadius: "100%",
                                width: "1.8em",
                                marginLeft: '10px',
                                padding: "0px"
                              }}
                            />
                            <span className="text-success">Copied</span>
                          </>
                        ) : (
                          <>
                            <IoIosLink
                              className="m-1 btn btn-primary"
                              style={{
                                height: "1.8em",
                                borderRadius: "100%",
                                width: "1.8em",
                                marginLeft: '10px',
                                padding: "5px"
                              }}
                            />
                            <span className="text-primary">Copy Link</span>
                          </>
                        )}
                        </div>}
                        <a href="https://community.savageseller.com" target="_blank" className={`mt-4 btn btn-primary ${pageNamem ? 'mr-3' : ''}`} disabled={true}>Return To Group Hub
                        </a>
                        {pageNamem ? (
                        <button
                          className="mt-4 btn btn-primary createBtn"
                          onClick={handleInput}
                          disabled={loading || isProductCreated}
                          style={{cursor:`${loading || isProductCreated ? 'not-allowed' : 'pointer'}`}}
                        >
                          {loading ? "Loading..." : "Create"}
                        </button>
                      ) : (
                        <button
                          className="mt-4 btn btn-success updateBtn"
                          onClick={updateHandler}
                          disabled={uploading}
                          style={{cursor:`${uploading ? 'not-allowed' : 'pointer'}`}}
                        >
                          {uploading ? "Loading..." : "Update"}
                        </button>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ThemeMaker>
      {currentPath === '/createSellerProduct' && 
        <Snackbar
            open={msgopen}
            autoHideDuration={10000} 
            onClose={handleMsgClose}
            key={vertical + horizontal}
            anchorOrigin={{ vertical, horizontal }}
          >
            <MuiAlert
              onClose={handleMsgClose} 
              sx={{
                width: "100%",
                padding: "10px",
                fontWeight: 600,
                color:"#ffffff",
                background: "#105293", 
                fontSize: "18px",
                borderRadius: "0.35rem",
              }}
            >
              Please ensure that all product details, such as title, description, images, pricing, stock levels, etc., are accurate and clear. Providing complete, precise info with quality images boosts visibility and builds customer trust.
            </MuiAlert>
        </Snackbar>
      }
      {(isProductCreated && currentPath === '/createSellerProduct') && 
        <Snackbar
            open={linkPopUp}
            autoHideDuration={2 * 60 * 1000} 
            onClose={handleLinkClose}
            key={vertical + horizontal}
            anchorOrigin={{ vertical, horizontal }}
          >
            <MuiAlert
              onClose={handleLinkClose} 
              sx={{
                width: "100%",
                padding: "15px 10",
                fontWeight: 600,
                color:"#ffffff",
                background: "#105293", 
                fontSize: "18px",
                borderRadius: "0.35rem",
              }}
            >
            <div className="pb-2">You Can Copy Product Link From Here</div>
              <div className={`${linkCopied ? 'text-success' : 'text-primary' }`} style={{cursor: "pointer", display: "flex", alignItems: "center", justifyContent:"center", width:"200px",padding:"0px 4px", border:"1px solid",borderRadius:"0.35rem", backgroundColor:"#ffffff", }} onClick={copyProductLink}>
              {linkCopied ? (
                <>
                  <IoIosCheckmark
                    className="m-1 btn btn-success"
                    style={{
                      height: "1.8em",
                      borderRadius: "100%",
                      width: "1.8em",
                      marginLeft: '10px',
                      padding: "0px"
                    }}
                  />
                  <span className="text-success">Copied</span>
                </>
              ) : (
                <>
                  <IoIosLink
                    className="m-1 btn btn-primary"
                    style={{
                      height: "1.8em",
                      borderRadius: "100%",
                      width: "1.8em",
                      marginLeft: '10px',
                      padding: "5px"
                    }}
                  />
                  <span className="text-primary">Copy Product Link</span>
                </>
              )}
              </div>
            </MuiAlert>
        </Snackbar>
      }
    </>
  );
}