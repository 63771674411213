import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import "../../index.css";
import CircularProgress from "@mui/material/CircularProgress";

const { apiEndpoint } = config;

interface ICustomer {
  id: any;
}

export default function UpdateUser() {
  let params: ICustomer = useParams();
  const { id } = params;
  const [pageNamem, setpageName] = useState(true);
  const [roleData, setRoleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUpLoading] = useState(false);
  const decode = tokenDecode();
  const rawData = {
    id: "",
    name: "",
    email: "",
    contact_no: "+1",
    role_id: "",
    status: false,
  };
  const errorRawData = {
    name: false,
    email: false,
    contact_no: false,
    role_id: false,
  };
  const [userData, setUserData]: any = useState(rawData);
  const [userDataError, setUserDataError]: any = useState(errorRawData);

  const getRole = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/role/getrolelist`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
        },
      });
      // console.log("role listing", data);
      if (data.status === 200) {
        setRoleData(data.data);
      }
    } catch (error) {}
  };
  const getUser = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios({
        url: `${apiEndpoint}/user/getuser`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      // console.log("user listing", data);
      if (data.status === 200) {
        setUserData(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const inputHandler = (e: any) => {
    if (e.target.name === "contact_no") {
      if (e.target.value === "+1" || e.target.value === "") {
        setUserData({ ...userData, [e.target.name]: "+1" });
      } else if (e.target.value.length === 1 && !isNaN(e.target.value)) {
        setUserData({ ...userData, [e.target.name]: "+1" + e.target.value });
      } else if (e.target.value.length > 1 && !isNaN(e.target.value)) {
        setUserData({ ...userData, [e.target.name]: e.target.value });
      }
    }
    else{
    setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };
  const errorHandler = (e: any) => {
    if (e.target.value) {
      setUserDataError({ ...userDataError, [e.target.name]: "valid" });
    } else {
      setUserDataError({ ...userDataError, [e.target.name]: true });
    }
  };
  const updateUserHandler = async () => {
    try {
      setUpLoading(true);
      const { name, email, contact_no, role_id } = userData;
      if (name && email && contact_no && role_id) {
        const { data } = await axios({
          url: `${apiEndpoint}/user/userupdate`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: userData,
        });
        setUpLoading(false);
        if (data.status === 200) {
          toast.success("User updated successfully !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("404 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Please fill all the fields !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setUpLoading(false);
      toast.error("404 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {
    getRole();
    getUser();
  }, []);
  const formatContactNumber = (value: any) => {
    if (!value) return "";
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return value;
  };
  const handleContactChange = (e: any) => {
    const inputVal = e.target.value;
    const cleaned = inputVal.replace(/[^\d]/g, "");
    let formattedValue = cleaned;
    if (!cleaned.startsWith("1")) {
      formattedValue = "1" + cleaned;
    }
    formattedValue = formatContactNumber(`+${formattedValue}`);


    setUserData({ ...userData, contact_no: formattedValue });
  };
  return (
    <>
      <ThemeMaker>
        <div className="row w-100">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow my-2">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Update user
                </h6>
              </div>
              <div className="card-body" style={{ minHeight: "70vh",width:"100%" }}>
                {!isLoading ? (
                  <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">
                        Name
                        <span className="text-danger"> * </span>
                        {userDataError?.name !== "valid" &&
                        userDataError?.name !== false ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter name"
                        value={userData.name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">
                        Email address
                        <span className="text-danger"> * </span>
                        {userDataError?.email !== "valid" &&
                        userDataError?.email !== false ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={userData.email}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">
                        Contact
                        <span className="text-danger"> * </span>
                        {userDataError?.contact_no !== "valid" &&
                        userDataError?.contact_no !== false ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name="contact_no"
                        className="form-control"
                        placeholder="Enter mobile"
                        value={userData.contact_no}
                        onChange={handleContactChange}
                        onBlur={(e) => errorHandler(e)}
                        maxLength={12}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">
                        Role
                        <span className="text-danger"> * </span>
                        {userDataError?.role_id !== "valid" &&
                        userDataError?.role_id !== false ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                      </label>
                      <select
                        name="role_id"
                        className="form-control"
                        id=""
                        value={userData.role_id}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      >
                        <option value="">Select role</option>
                        {roleData
                          .filter((roledata: any) => {
                            if (roledata.status) {
                              return roleData;
                            }
                          })
                          .map((item: any) => (
                            <option value={item?.id}>
                              {" "}
                              {item?.role_name}{" "}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="form-group d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <label className="m-0 genericlabel">Status</label>

                      <input
                        type="checkbox"
                        checked={userData.status}
                        value={userData.contact_no}
                        onChange={(e) =>
                          setUserData({ ...userData, status: !userData.status })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={updateUserHandler}
                    >
                      {loading ? "updating..." : "Update"}
                    </button>
                  </div>
                </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }} >
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
