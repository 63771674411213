import { useState, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import { Link, useParams } from "react-router-dom";
import { config } from "../../utils/config";
import { v4 as uuidv4 } from "uuid";
import { show, hide } from "react-functional-modal";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";
import styled from "styled-components";

const { apiEndpoint } = config;

const s3Config = {
  bucketName: "bucket-name",
  dirName: "directory-name" /* Optional */,
  region: "ap-south-1",
  accessKeyId: "ABCD12EFGH3IJ4KLMNO5",
  secretAccessKey: "a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0",
  s3Url: "https:/your-aws-s3-bucket-url/" /* Optional */,
};

const aws = require("aws-sdk");

const ImageContainer = styled.div`
  border: 1px solid black;
  border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 100% !important;
    height: 80%;
    object-fit: contain;
  }

  button {
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
`;

export default function Personaldetails(props) {
  let rawToken = localStorage.getItem("us_st_d");
  var decode = jwtDecode(rawToken);
  // console.log("decode-->", decode)
  let decodedUserdata = decode.data;
  const isApproved = decodedUserdata?.seller_data?.approve_status;
  const param = useParams();
  const id = param.id;

  const rawData = {
    id: id,
    street: "",
    city: decodedUserdata.seller_data.city,
    area_code: "",
    state: "",
    personal_address: "",
    pickup: "",
    dropoff: "",
    meetpublicplace: "",
    pickdropmeet: false,
    localDelivery:[],
  };
  const rawErrorData = {
    street: false,
    city: false,
    area_code: false,
    state: false,
    personal_address: false,
    pickup: false,
    dropoff: false,
    meetpublicplace: false,
    pickdropmeet: false,
    localDelivery: false,
  };
  const [personalData, setpersonalData] = useState(rawData);
  const [personalDataError, setpersonalDataError] = useState(rawErrorData);

  const renderAfterCalled = useRef(false);
  const zipCodePattern = /^[0-9]{5}$/;

  async function givedta() {
    const { data } = await axios({
      url: `${apiEndpoint}/seller/getseller`,
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("us_st_d")}`,
      },
      data: {
        seller_id: decodedUserdata?.seller_data?.id,
      },
    });
    // console.log(data);
    if (data.status === 200) {
      setpersonalData(data.response);
      renderAfterCalled.current = true;
    }
  }

  if (!renderAfterCalled.current) {
    givedta();
  }
  const inputHandler = (e) => {
    const { name, value } = e.target;

    if (name === "localDelivery") {
      setpersonalData({
        ...personalData,
        [name]: value.split(",").map((zip) => zip)
      });
    } else {
      setpersonalData({
        ...personalData,
        [name]: value
      });
    }
  };
  const errorHandler = (e) => {
    const { name, value } = e.target;

    if (name === "localDelivery") {
        const zipCodes = value.split(",").map(zip => zip.trim()).filter(zip => zip !== "");
        const uniqueZipCodes = [...new Set(zipCodes)];
        const invalidZipCodes = uniqueZipCodes.filter(zip => !/^\d{5}$/.test(zip));
        
        if (invalidZipCodes.length > 0) {
            setpersonalDataError({
                ...personalDataError,
                localDelivery: `Invalid zip codes: ${invalidZipCodes.join(", ")}`
            });
        } else {
            setpersonalDataError({ ...personalDataError, localDelivery: false });
        }
        setpersonalData({
            ...personalData,
            localDelivery: uniqueZipCodes
        });
    } else {
        if (e.target.value) {
            setpersonalDataError({ ...personalDataError, [e.target.name]: false });
        } else {
            setpersonalDataError({ ...personalDataError, [e.target.name]: true });
        }
    }
};

  
  const onSubmitHandler = async () => {
    const { id, street, city, area_code, state, personal_address } =
      personalData;
    if (personal_address && state && city && area_code && street) {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/personaldetails`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: personalData,
        });
        if (data.status === 200) {
          toast.success("Details submitted Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.error("Please fill all the fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const productImageHandler = async (e) => {
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: config?.awsKeyId,
        secretAccessKey: config?.awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let result = await s3.upload(params).promise();
      const { Location } = result;
      // console.log(Location);
      setpersonalData({ ...personalData, [e.target.name]: Location });
    } catch (exception) {
      console.log(exception);
    }
  };
  const open = (image) => {
    show(
      <ImageContainer>
        <img src={image} alt="" />
        <button
          onClick={() => {
            hide("key#1");
          }}
        >
          x
        </button>
      </ImageContainer>,
      { key: "key#1" }
    );
  };

  return (
    <>
    {!personalData.personal_address ? (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
        <CircularProgress />
      </div>
    ) : (
      <div className="row m-0 w-100 mt-1">
        <div className="col-md-12" style={{ background: "white" }}>
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ color: "black", fontWeight: 600 }}>
                      Street<span className="text-danger">*</span>
                      {personalDataError?.street !== "valid" &&
                      personalDataError?.street !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="street"
                      placeholder="Enter Street"
                      value={personalData.street}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="text-dark"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      City<span className="text-danger">*</span>
                      {personalDataError?.city !== "valid" &&
                      personalDataError?.city !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder="Enter City"
                      value={personalData.city}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="text-dark"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Area PIN Code<span className="text-danger">*</span>
                      {personalDataError?.area_code !== "valid" &&
                      personalDataError?.area_code !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="number"
                      name="area_code"
                      className="form-control"
                      placeholder="Enter Area Code"
                      value={personalData.area_code}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="text-dark"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      State<span className="text-danger">*</span>
                      {personalDataError?.state !== "valid" &&
                      personalDataError?.state !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      placeholder="State"
                      value={personalData.state}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="text-dark"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Address <span className="text-danger">*</span>
                      {personalDataError?.personal_address !== "valid" &&
                      personalDataError?.personal_address !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="personal_address"
                      placeholder="Enter Address"
                      value={personalData.personal_address}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="text-dark"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Pickup Location {" "}
                      <span className="d-inline-block cursor-pointer" title="Adding a pickup location helps customers know where to collect their orders, improving their overall experience." >
                        <InfoIcon color="primary" fontSize="small" />
                      </span>
                      {/* <span className="text-danger">*</span>
                      {personalDataError?.pickup !== "valid" &&
                      personalDataError?.pickup !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null} */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="pickup"
                      placeholder="Enter Pickup Location"
                      value={personalData.pickup}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="text-dark"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Drop-Off Location {" "}
                      <span className="d-inline-block cursor-pointer" title="Make sure to include a drop-off location to streamline the return or exchange process for your customers." >
                        <InfoIcon color="primary" fontSize="small" />
                      </span>
                      {/* <span className="text-danger">*</span>
                      {personalDataError?.dropoff !== "valid" &&
                      personalDataError?.dropoff !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null} */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="dropoff"
                      placeholder="Enter Drop-Off Location"
                      value={personalData.dropoff}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="text-dark"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Meet At Public Place {" "}
                      <span className="d-inline-block cursor-pointer" title="Make sure to specify a meet-at public place to offer customers a safe location for pickups and deliveries." >
                        <InfoIcon color="primary" fontSize="small" />
                      </span>
                      {/* <span className="text-danger">*</span>
                      {personalDataError?.meetpublicplace !== "valid" &&
                      personalDataError?.meetpublicplace !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null} */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="meetpublicplace"
                      placeholder="Enter Meet At Public Place"
                      value={personalData.meetpublicplace}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label
                    className="text-dark"
                    style={{fontWeight: 600 }}
                  >
                    Pick Up, Drop Off, and Meet in Public Place
                  </label>
                  <div className="d-flex mb-4">
                    <div className={`toggleBtn`} >
                      <input
                        type="checkbox"
                        className="toggleCheck"
                        checked={personalData.pickdropmeet}
                        onChange={() =>
                          setpersonalData((prev) => ({
                            ...prev,
                            pickdropmeet: !prev.pickdropmeet,
                          }))
                        }
                      />
                      <div className="knobs"></div>
                      <div className="layer"></div>
                    </div>
                    <div className="ml-2">
                      {personalData.pickdropmeet ? "Enabled" : "Disabled"}{" "}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group text-dark">
                      <label style={{fontWeight: 600 }}>
                        Local Delivery Zip Codes
                        {/* <span className="text-danger">*</span> */}
                        {personalDataError?.localDelivery !== false ? (
                          <span className="text-danger tiny_size ml-2"> Please enter valid zip codes!</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name="localDelivery"
                        className="form-control"
                        placeholder="Enter zip codes separated by commas (e.g., 12345,23451,...)"
                        value={personalData.localDelivery ? personalData.localDelivery.join(",") : ""}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      />
                      {personalDataError?.localDelivery && (
                        <span className="text-danger tiny_size">{personalDataError.localDelivery}</span>
                      )}
                    </div>
                  </div>
                <div className="col-md-6" style={{ display: "none" }}>
                  <div className="form-group">
                    <label style={{ color: "black", fontWeight: 600 }}>
                      Short Description of Shop
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="short_description"
                      placeholder="Enter Short Description"
                      value={personalData.short_description}
                      onChange={(e) => inputHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6" style={{ display: "none" }}>
                  <div className="form-group">
                    <label
                      className="text-dark"
                      style={{ color: "black", fontWeight: 600 }}
                    >
                      Long Description of Shop
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="Enter Long Description"
                      value={personalData.description}
                      onChange={(e) => inputHandler(e)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      onSubmitHandler();
                      setTimeout(() => {
                        props.pageChangeController();
                      }, 3000);
                    }}
                  >
                    Update
                  </button>
                </div>
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    
    </>
  );
}
