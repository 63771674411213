import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "../../store/features/login/loginSlice";
import { useHistory } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const loginData = useSelector((state: any) => state.loginReducer);
  // console.log("loginData--->", loginData);
  const { loading } = loginData;
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    email: true,
    password: true,
  });
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const loginMannage = async () => {
    // console.log("user---");
    dispatch(checkLogin(user));
  };
  const loginChecker = (e: any) => {
    e.preventDefault();
    if (errorMessage.email || errorMessage.password) {
      toast.error("Email and password is required !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      loginMannage();
    }
  };
  const onChangeHandler = (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: e.target.value ? false : true,
    });
  };
  const redirectHandler = () => {
    try {
      const decode = tokenDecode();
      const currentTime = Date.now() / 1000;
      if (decode) {
        history.push("/dashboard");
      } else {
        history.push("/");
      }
    } catch (error) {
      history.push("/");
    }
  };
  useEffect(() => {
    redirectHandler();
  }, [loginData]);


  return (
    <>
      <div className="">

        <section
          className="d-flex align-items-center"
          style={{ height: "100vh", backgroundColor: "#dfe7f0" }}
        >
          <div className="container main_container">
            <div className="row col-sm-6 m-auto shadow-regular align-items-center bg-white rounded rounded-4 shadow-sm p-3 mb-5 flex_direction">
             
              <div
                className="loginFormat col-lg-12 col-sm-12 col-md-12 p-5"
                id="padding"
              >
                <div className="imagesSetup">
                    <img
                      className="w-100"
                      src="img/savageseller-logo.jpg"
                      alt="loginCard"
                    />
                  </div>
                {/* <img className='d-block m-auto w-50' src={logo} alt="" /> */}
                <form
                  className="user mb-4 mt-2"
                  onSubmit={(e) => loginChecker(e)}
                >
                  <div className="position-relative">
                    <div className="form-group mt-3">
                      <input
                        type="email"
                        className="form-control form_padding"
                        placeholder="Enter Email Address..."
                        name="email"
                        onChange={(e) => onChangeHandler(e)}
                      />
                    </div>
                    <span className="Icons">
                      <PersonOutlineIcon />
                    </span>
                  </div>
                  <div className="position-relative">
                    <div className="form-group mt-3 mb-2">
                    <div className="position-relative w-[80%] m-auto">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control form_padding"
                        placeholder="Password"
                        name="password"
                        onChange={(e) => onChangeHandler(e)}
                      />
                    
                    
                    <span className="Icons">
                      <LockOpenIcon />
                    </span>
                     <div
                        className="position-absolute"
                        style={{ right: "8px", top: "50%", transform: "translateY(-50%)", cursor: "pointer", color: "#6b7280" }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                      </div>
                      </div>
                  </div>
                  <div className="">
                    <Link className="small" to="/forget-password">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="login_btn mt-3 rounded rounded-3">
                    {/* <button className='btn text-white w-100'>Login Now</button> */}
                    {loading ? (
                      <button className="btn text-white w-100">
                        Loading...
                      </button>
                    ) : (
                      <button className="btn text-white w-100">Login</button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* </div> */}
      </div>
      <ToastContainer />
    </>
  );
}
