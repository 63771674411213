import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ThemeMaker from "../../components/thememaker";
import { useParams } from "react-router-dom";
import { registerSeller } from "../../store/features/seller/sellerSlice";
import { useSelector, useDispatch } from "react-redux";
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import "../../index.css";

const aws = require("aws-sdk");

interface ICustomer {
  id: any;
}

export default function AddBusinessSeller() {
  const dispatch = useDispatch<any>();
  const _sellerData = useSelector((state: any) => state.sellerReducer);
  let params: ICustomer = useParams();
  const [isgstValid, setisgstValid] = useState(true);
  const [ispanValid, setispanValid] = useState(true);
  const [isPanPresent, setispanPresent] = useState(false);
  const [loading, setLoading] = useState({
    image1: false,
    image2: false,
    button: false,
  });
  const [isImageUploading, setIsImageUploading] = useState(false);
  const rawData = {
    name: "",
    email: "",
    company_name: "",
    contact_no: "+1",
    commission: 7,
    seller_id: 0,
    role_id: 2,
    status: 1,
    fulfillment_option: "",
    business_entity_type: "",
    tax: "",
    taxnumber: "",
    sellerType:"business",
    shipping_method: "",
    profile_image: "",
    cardnumber: "",
    varifydoc: "",
    varifydocimage: "",
    street: " ",
    city: " ",
    area_code: " ",
    state: " ",
    personal_address: " ",
    bank_account: " ",
    ifsc_code: " ",
    beneficiary_name: " ",
    description:"",
    terms:"",
    pickup: "",
    dropoff: "",
    meetpublicplace: "",
    pickdropmeet: false,
    localDelivery:[],
  };
  const rawErrorData = {
    name: false,
    email: false,
    company_name: false,
    contact_no: false,
    commission: false,
    fulfillment_option: false,
    business_entity_type: false,
    tax: false,
    taxnumber: false,
    sellerType:false,
    shipping_method: false,
    profile_image: false,
    cardnumber: false,
    varifydoc: false,
    varifydocimage: false,
    street: false,
    city: false,
    area_code: false,
    state: false,
    personal_address: false,
    bank_account: false,
    ifsc_code: false,
    beneficiary_name: false,
    description:false,
    terms:false,
    pickup: false,
    dropoff: false,
    meetpublicplace: false,
    pickdropmeet: false,
    localDelivery:false,
  };
  const [sellerData, setSellerData]: any = useState(rawData);
  const [sellerDataError, setSellerDataError]: any = useState(rawErrorData);
  const inputHandler = (e: any) => {
    const { name, value } = e.target;
    if (name === "contact_no") {
      if (value === "+1" || value === "") {
          setSellerData({ ...sellerData, [name]: "+1" });
      } else if (value.length === 1 && !isNaN(value)) {
          setSellerData({ ...sellerData, [name]: "+1" + value });
      } else if (value.length > 1 && !isNaN(value)) {
          setSellerData({ ...sellerData, [name]: value });
      }
    } else if (name === "area_code") {
      if (!isNaN(value)) {
        setSellerData({ ...sellerData, [name]: value });
        setSellerDataError({ ...sellerDataError, [name]: "valid" });
      } else {
        setSellerDataError({ ...sellerDataError, [name]: "invalid" });
      }
    } else if (name === "localDelivery") {
      setSellerData({
        ...sellerData,
        [name]: value.split(",").map((zip:any) => zip.trim()) 
      });
    }
    else{
      setSellerData({ ...sellerData, [name]: value });
    }
  };
  const errorHandler = (e: any) => {
    // console.log(e.target.name);
    const { name, value } = e.target;
    const gstregex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    var panregex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if (value !== "") {
      if (name === "gst_no") {
        if (gstregex.test(value)) {
          setisgstValid(true);
        } else {
          setisgstValid(false);
        }
      }
      if (name === "pan_no") {
        if (panregex.test(value)) {
          setispanValid(true);
        } else {
          setispanValid(false);
        }
      }
      if (name === "area_code") {
        if (!isNaN(value)) {
          setSellerDataError({ ...sellerDataError, [name]: "valid" });
        } else {
          setSellerDataError({ ...sellerDataError, [name]: "invalid" });
        }
      }
    }
    if (value) {
      setSellerDataError({ ...sellerDataError, [name]: "valid" });
    } else {
      setSellerDataError({ ...sellerDataError, [name]: true });
    }
    if (name === "localDelivery") {
      const zipCodes = value.split(",").map((zip:any) => zip.trim()).filter((zip:any) => zip !== ""); 
      const uniqueZipCodes = [...new Set(zipCodes)];
      const invalidZipCodes = uniqueZipCodes.filter((zip:any) => !/^\d{5}$/.test(zip));
      
      if (invalidZipCodes.length > 0) {
          setSellerDataError({
              ...sellerDataError,
              localDelivery: `Invalid zip codes: ${invalidZipCodes.join(", ")}`
          });
      } else {
          setSellerDataError({ ...sellerDataError, localDelivery: false });
      }
      setSellerData({
          ...sellerData,
          localDelivery: uniqueZipCodes
      });
    }
  };

  const newUserCreatehandler = async () => {
    setLoading({ ...loading, button: true });
    const {
      name,
      email,
      company_name,
      contact_no,
      commission,
      seller_id,
      role_id,
      status,
      fulfillment_option,
      business_entity_type,
      tax,
      taxnumber,
      sellerType,
      shipping_method,
      varifydoc,
      varifydocimage,
      profile_image,
      cardnumber,
      description,
      terms,
      street,
      city,
      area_code,
      state,
      personal_address,
    } = sellerData;
    console.log(sellerData)
    if (!area_code.trim()) {
      setSellerDataError((prevError:any) => ({
        ...prevError,
        area_code: true,
      }));
      setLoading({ ...loading, button: false });
      toast.error("Area code is required!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (
      name &&
      email &&
      company_name &&
      contact_no &&
      commission &&
      seller_id === 0 &&
      role_id &&
      status &&
      fulfillment_option &&
      business_entity_type &&
      tax &&
      taxnumber &&
      varifydoc &&
      varifydocimage &&
      profile_image &&
      cardnumber &&
      description && 
      terms &&
      street &&
      city &&
      area_code &&
      state &&
      personal_address
    ) {
      let a = dispatch(registerSeller({...sellerData,sellerType:"business",paid:true}));
      console.log(a);
      setLoading({ ...loading, button: false });
      // console.log(">>>>>>>>>>>>", sellerData)
    } else {
      toast.error("Please fill all the fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
 
  const ImageHandler = async (e: any) => {
    if (e.target.name == "pan_image") {
      setLoading({ ...loading, image1: true });
    } else {
      setLoading({ ...loading, image2: true });
    }
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: config?.awsKeyId,
        secretAccessKey: config?.awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      if (e.target.name == "pan_image") {
        setLoading({ ...loading, image1: false });
      } else {
        setLoading({ ...loading, image2: false });
      }
      return Location;
    } catch (exception) {
      console.log(exception);
      return false;
    }
  };
  const setImage = async (e: any) => {
    setIsImageUploading(true)
    const image: any = await ImageHandler(e);
    setSellerData({ ...sellerData, [e.target.name]: image });
    setIsImageUploading(false)
  };
  const formatContactNumber = (value: any) => {
    if (!value) return "";
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return value;
  };
  const handleContactChange = (e: any) => {
    const inputVal = e.target.value;
    const cleaned = inputVal.replace(/[^\d]/g, "");
    let formattedValue = cleaned;
    if (!cleaned.startsWith("1")) {
      formattedValue = "1" + cleaned;
    }
    formattedValue = formatContactNumber(`+${formattedValue}`);


    setSellerData({ ...sellerData, contact_no: formattedValue });
  };
  return (
    <>
      <ThemeMaker>
        <div className="row m-0 w-100 mt-1">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Add a new Business seller
                </h6>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Name
                        <span className="text-danger"> * </span>
                        {sellerDataError?.name !== "valid" &&
                        sellerDataError?.name !== false ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        value={sellerData.name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Company name<span className="text-danger">*</span>
                        {sellerDataError?.company_name !== "valid" &&
                        sellerDataError?.company_name !== false ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="company_name"
                        placeholder="Company name"
                        value={sellerData.company_name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Email address<span className="text-danger">*</span>
                        {sellerDataError?.email !== "valid" &&
                        sellerDataError?.email !== false ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={sellerData.email}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Contact No.<span className="text-danger">*</span>
                        {sellerDataError?.contact_no !== "valid" &&
                        sellerDataError?.contact_no !== false ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                         <span className="text-primary text-xs top-2 left-2">Only USA phone numbers are allowed</span>
                      </label>
                      <input
                        type="text"
                        name="contact_no"
                        className="form-control"
                        placeholder="Contact number"
                        value={sellerData.contact_no}
                        onChange={handleContactChange}
                        onBlur={(e) => errorHandler(e)}
                        maxLength={12}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  
                 

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Commission ( % )<span className="text-danger"> *</span>
                        {sellerDataError?.commission !== "valid" &&
                        sellerDataError?.commission !== false ? (
                          <span className="text-danger tiny_size">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                      </label>
                      <input
                        type="number"
                        name="commission"
                        className="form-control"
                        placeholder="Commission"
                        value={sellerData.commission}
                        // onChange={(e) => inputHandler(e)}
                        // onBlur={(e) => errorHandler(e)}
                        // disabled={isImageUploading}
                        disabled={true}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel d-flex">
                        Profile image
                        <span className="text-danger"> * </span>

                      {sellerDataError?.profile_image !== "valid" &&
                      sellerDataError?.profile_image !== false ? (
                         <>
                         <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                        
                         
                          </>
                      ):""}
                       <a
                            href={sellerData.profile_image}
                            target="_blank"
                            className="small_text"
                          >
                            {" "}
                            View Document
                          </a>
                          {isImageUploading ? <div className="flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4335 4335" width="20" className="loading" style={{ color: "#0ba934e0" }}> <path fill="#008DD2" d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z" /></svg><span style={{ color: "#0ba934e0",fontSize:"12px" }}>Uploading.....</span></div> : null}
                      </label>
                      <input
                        type="file"
                        name="profile_image"
                        className="form-control"
                        onChange={(e) => setImage(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Country</label>
                      
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        value="USA"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Address</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.personal_address !== "valid" &&
                      sellerDataError?.personal_address !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="personal_address"
                        placeholder="Address"
                        value={sellerData.personal_address}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">State</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.state !== "valid" &&
                      sellerDataError?.state !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        id="State"
                        className="form-control"
                        name="state"
                        // placeholder="State"
                        value={sellerData.state}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">City</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.city !== "valid" &&
                      sellerDataError?.city !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="City"
                        value={sellerData.city}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Area code</label>
                      <span className="text-danger"> * </span>
                      {sellerDataError?.area_code === "invalid" ? (
                          <span className="text-danger tiny_size">
                            Please enter a valid numeric value!
                          </span>
                        ) : sellerDataError?.area_code !== "valid" &&
                          sellerDataError?.area_code !== false ? (
                          <span className="text-danger tiny_size">
                            This field is required!
                          </span>
                        ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="area_code"
                        placeholder="Area code"
                        value={sellerData.area_code}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Street</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.street !== "valid" &&
                      sellerDataError?.street !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="street"
                        placeholder="Street"
                        value={sellerData.street}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                 
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Credit Card Number
                        <span className="text-danger"> * </span>
                      </label>

                      {sellerDataError?.cardnumber !== "valid" &&
                      sellerDataError?.cardnumber !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="cardnumber"
                        placeholder="Credit Card Number"
                        value={sellerData.cardnumber}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        maxLength={15}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                 
                  <div className="col-md-4">
                    <label className="genericlabel">
                      {" "}
                      Verification Documents{" "}
                      <span className="text-danger">*</span>
                      {sellerDataError?.varifydoc !== "valid" &&
                      sellerDataError?.varifydoc !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <div className="form-group">
                      <select
                        name="varifydoc"
                        id=""
                        className="form-control"
                        value={sellerData.varifydoc}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      >
                        <option value="">
                          Select Documentation verifying the business's legal
                          existence and tax status
                        </option>
                        <option value="business_licenses">
                          Business Licenses
                        </option>
                        <option value="article">
                          Articles of incorporation
                        </option>
                        <option value="tax certificate">
                          Tax registration certificates
                        </option>
                        <option value="utility bills">Utility bills</option>
                      </select>
                    </div>
                  </div>
                 
                  <div className="col-md-4">
                  <label className="genericlabel d-flex">
                  Document Verification Image
                  <span className="text-danger"> * </span>

                      {sellerDataError?.varifydocimage !== "valid" &&
                      sellerDataError?.varifydocimage !== false ? (
                         <>
                         <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                        
                         
                          </>
                      ):""}
                       <a
                            href={sellerData.varifydocimage}
                            target="_blank"
                            className="small_text"
                          >
                            {" "}
                            View Document
                          </a>
                          {isImageUploading ? <div className="flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4335 4335" width="20" className="loading" style={{ color: "#0ba934e0" }}> <path fill="#008DD2" d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z" /></svg><span style={{ color: "#0ba934e0",fontSize:"12px" }}>Uploading.....</span></div> : null}
                      </label>
                   
                    <div className="form-group">
                      <input
                        type="file"
                        name="varifydocimage"
                        className="form-control"
                        onChange={(e) => setImage(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
             
                  <div className="col-md-4">
                    <label className="genericlabel">
                      {" "}
                      Tax Information <span className="text-danger">*</span>
                      {sellerDataError?.tax !== "valid" &&
                      sellerDataError?.tax !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <div className="form-group">
                      <select
                        name="tax"
                        id=""
                        className="form-control"
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        value={sellerData.tax}
                        disabled={isImageUploading}
                      >
                        <option value="">Select Tax Information</option>
                        <option value="tin">Tax identification Number</option>
                        <option value="ein">
                          Employer Identification Number
                        </option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Tax Information Number
                      </label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.taxnumber !== "valid" &&
                      sellerDataError?.taxnumber !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="taxnumber"
                        placeholder="Tax Information Number"
                        value={sellerData.taxnumber}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  
                  <div className="col-md-4">
                    <label className="genericlabel">
                      {" "}
                      Business entity type{" "}
                      <span className="text-danger">*</span>
                      {sellerDataError?.business_entity_type !== "valid" &&
                      sellerDataError?.business_entity_type !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <div className="form-group">
                      <select
                        name="business_entity_type"
                        id=""
                        className="form-control"
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        value={sellerData.business_entity_type}
                        disabled={isImageUploading}
                      >
                        <option value="">Select Business Entity</option>
                        <option value="partnership">Partnership</option>
                        <option value="corporation">Corporation</option>
                        <option value="limited">
                          Limited liability company
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Bank account
                        <span className="text-danger"> * </span>
                      </label>

                      {sellerDataError?.bank_account !== "valid" &&
                      sellerDataError?.bank_account !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="bank_account"
                        placeholder="Bank account"
                        value={sellerData.bank_account}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Routing Number </label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.ifsc_code !== "valid" &&
                      sellerDataError?.ifsc_code !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="ifsc_code"
                        placeholder="Enter Routing Number"
                        value={sellerData.ifsc_code}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Beneficiary name </label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.beneficiary_name !== "valid" &&
                      sellerDataError?.beneficiary_name !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="beneficiary_name"
                        placeholder="Beneficiary name"
                        value={sellerData.beneficiary_name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                
                  <div className="col-md-4">
                    <label className="genericlabel">
                      {" "}
                      Fulfillment Option <span className="text-danger">*</span>
                      {sellerDataError?.fulfillment_option !== "valid" &&
                      sellerDataError?.fulfillment_option !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <div className="form-group">
                      <select
                        name="fulfillment_option"
                        id=""
                        className="form-control"
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        value={sellerData.fulfillment_option}
                        disabled={isImageUploading}
                      >
                        <option value="">Select Fulfillment options</option>
                        <option value="UPS">UPS</option>
                        <option value="USPS">USPS</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Pickup Location</label>
                      {/* <span className="text-danger"> * </span>
                      {sellerDataError?.pickup !== "valid" &&
                      sellerDataError?.pickup !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null} */}
                      <input
                        type="text"
                        className="form-control"
                        name="pickup"
                        placeholder="Enter Pickup Location"
                        value={sellerData.pickup}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        // disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Drop-Off Location</label>
                      {/* <span className="text-danger"> * </span>
                      {sellerDataError?.dropoff !== "valid" &&
                      sellerDataError?.dropoff !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null} */}
                      <input
                        type="text"
                        className="form-control"
                        name="dropoff"
                        placeholder="Enter Drop-Off Location"
                        value={sellerData.dropoff}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        // disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Meet At Public Place</label>
                      {/* <span className="text-danger"> * </span>
                      {sellerDataError?.meetpublicplace !== "valid" &&
                      sellerDataError?.meetpublicplace !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null} */}
                      <input
                        type="text"
                        className="form-control"
                        name="meetpublicplace"
                        placeholder="Enter Meet At Public Place"
                        value={sellerData.meetpublicplace}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        // disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  
                  <div className="col-md-8">
                    <div className="form-group">
                      <label className="genericlabel">
                        Product Description{" "}
                      </label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.description !== "valid" &&
                      sellerDataError?.description !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <textarea
                        className="form-control"
                        name="description"
                        placeholder="Details about the products the business intends to sell, including categories, UPC/EAN/ISBN codes (if applicable), and product condition (new, used, refurbished)."    
                        value={sellerData.description}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        // style={{ height: "150px" }}
                        rows={3}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="genericlabel">
                      Pick Up, Drop Off, and Meet in Public Place
                    </label>
                    <div className="d-flex mb-4">
                      <div className={`toggleBtn`}>
                        <input
                          type="checkbox"
                          className="toggleCheck"
                          checked={sellerData.pickdropmeet}
                          onChange={() =>
                            setSellerData((prev:any) => ({
                              ...prev,
                              pickdropmeet: !prev.pickdropmeet,
                            }))
                          }
                        />
                        <div className="knobs"></div>
                        <div className="layer"></div>
                      </div>
                      <div className="ml-2">
                        {sellerData.pickdropmeet ? "Enabled" : "Disabled"}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                      <label className="genericlabel" style={{fontWeight: 600 }}>
                        Local Delivery Zip Codes {" "}
                        <span className="d-inline-block cursor-pointer" title="Enter zip codes separated by commas (e.g., 12345,23451,...)" >
                          <InfoIcon color="primary" fontSize="small" />
                        </span>
                        {/* <span className="text-danger">*</span> */}
                        {sellerDataError?.localDelivery !== false ? (
                          <span className="text-danger tiny_size"> Please enter valid zip codes!</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name="localDelivery"
                        className="form-control"
                        placeholder="Enter Zip Codes (e.g., 12345,23451,...)"
                        value={sellerData.localDelivery ? sellerData.localDelivery.join(",") : ""}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      />
                      {sellerDataError?.localDelivery && (
                        <span className="text-danger">{sellerDataError.localDelivery}</span>
                      )}
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel"> Marketplace Agreement </label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.terms !== "valid" &&
                      sellerDataError?.terms !== false ? (
                          <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                          </span>
                      ) : null}
                      <div className="form-check flex">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="termsAgreement"
                            checked={sellerData.terms}
                            name="terms"
                            onChange={(e) => {
                                const { checked } = e.target;
                                setSellerData({ ...sellerData, terms: checked });
                            }}
                            disabled={isImageUploading}
                          />
                          <label className="form-check-label px-2" htmlFor="termsAgreement">
                          Agreement to savage seller terms and policies, including the savage seller Services Business Solutions Agreement.
                          </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={newUserCreatehandler}
                    >
                      {_sellerData?.loading ? (
                        <div className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 4335 4335"
                            width="20"
                            className="loading"
                            style={{ color: "#0ba934e0" }}
                          >
                            {" "}
                            <path
                              fill="#008DD2"
                              d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                            />
                          </svg>
                          <span style={{ color: "#fff" }}>Uploading.....</span>
                        </div>
                      ) : (
                        "Create"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
