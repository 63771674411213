import { useState, useEffect } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { Paginated } from "../../components/Paginated";
import "../../components/table.css";
import Papa from "papaparse";
import "./Master.css";
import Pagination from "../../components/Paginated/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { IoLogoUsd,IoIosLink,IoIosCheckmark } from "react-icons/io";
import Applyfilter from "../../components/Applyfilter/Applyfilter";
import * as XLSX from "xlsx";
const { apiEndpoint } = config;

var $ = require("jquery");

var count = 1;

export default function ProductList() {
  const decoded = tokenDecode();
  let current_sellerid = decoded?.data?.user?.seller_id;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [importdata, setimportdata] = useState([]);
  const [maxprice, setmaxprice] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [productUrls,setProductUrls] = useState({});
  const [copiedRow, setCopiedRow] = useState(null);
  const [productisLoading, setproductisLoading] = useState(false);
  const [noproducts, setNoproducts] = useState(false);

  const handleCopyLink = (url, rowIndex) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopiedRow(rowIndex);
      setTimeout(() => {
        setCopiedRow(null);
      }, 2000); 
    });
  };

  const handleImport = async () => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        let abc = data.filter((item) => item.name !== null);
        let orders = abc.map((order) => ({
          name: order.name,
          position: order.position,
          product_type: order.product_type,
          pf_brand_owner_FSSAI_license_no:
            order.pf_brand_owner_FSSAI_license_no,
          short_description: order.short_description,
          description: order.description,
          pc_manufacturer_or_packer_name: order?.pc_manufacturer_or_packer_name,
          pc_common_or_generic_name_of_commodity:
            order?.pc_common_or_generic_name_of_commodity,
          pc_month_year_of_manufacturer_packing_import:
            order?.pc_month_year_of_manufacturer_packing_import,
          pf_nutritional_info: order?.pf_nutritional_info,
          pf_additives_info: order?.pf_additives_info,
          pf_other_fassai_license_no: order?.pf_other_fassai_license_no,
          pf_importer_fassai_license_no: order?.pf_importer_fassai_license_no,
          pc_manufacturer_or_packer_address:
            order?.pc_manufacturer_or_packer_address,
          pc_imported_product_country_of_origin:
            order?.pc_imported_product_country_of_origin,
          mrp: order.mrp,
          uom: order.uom,
          selling_price: order.selling_price,
          tax_included: order.tax_included,
          tax: order.tax,
          hsn: order.hsn,
          tags: order.tags,
          sku: order.sku,
          country_of_origin: order.country_of_origin,
          customer_care_contact: order.customer_care_contact,
          time_to_ship: order.time_to_ship,
          returnable: order.returnable,
          cancelable: order.cancelable,
          category_name: order.category_name,
          return_window: order.return_window,
          seller_pickup_return: order.seller_pickup_return,
          replaceable: order.replaceable,
          cod: order.cod,
          image: order.image,
          status: order.status,
          approve_status: order.approve_status,
          retail_approve_status: order.retail_approve_status,
          hair_type: order.hair_type,
          herbal_or_ayurvedic: order.herbal_or_ayurvedic,
          quantity: order.quantity,
          net_qty: order.net_qty,
          ready_to_eat: order.ready_to_eat,
          ready_to_cook: order.ready_to_cook,
          rice_grain_type: order.rice_grain_type,
          capacity: order.capacity,
          preservatives: order.preservatives,
          preservatives_details: order.preservatives_details,
          flavours_and_spices: order.flavours_and_spices,
          scented_or_flavour: order.scented_or_flavour,
          theme_or_occasion_type: order.theme_or_occasion_type,
          recommended_age: order.recommended_age,
          mineral_source: order.mineral_source,
          caffeine_content: order.caffeine_content,
          absorption_duration: order.absorption_duration,
          baby_weight: order.baby_weight,
          brand_name: order.brand_name,
          weight: order.weight,
          reference_id: order?.reference_id,
        }));
        let jsonData = {
          orders: orders,
        };
        sendDataToDb(jsonData);
      },
    });
  };
  async function sendDataToDb(jsonData) {
    try {
      setLoading(true);
      // console.log(jsonData);
      let result = await axios({
        url: `${apiEndpoint}/uploadFileData`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          // "Content-Type": "multipart/form-data"
        },
        data: jsonData,
      });
      setLoading(false);
      if (result.status === 200) {
        toast.success("Products added successfully !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFile(null);
        categoryListing();
        document.getElementById("inputFile").value = "";
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
  const [productList, setProductList] = useState([]);
  const [sproductList, setSproductList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [sellers, setSellers] = useState({
    seller: "",
  });
  const rawData = {
    seller_id: "",
  };
  const rawpData = {
    seller_id: "",
    min_price: "",
  };
  const rawsData = {
    seller_id: "",
    sku: "",
  };
  const Sstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
  };
  const [categories, setCategories] = useState();
  const [searchData, setSearchData] = useState(rawData);
  const [searchpData, setSearchpData] = useState(rawpData);
  const [searchsData, setSearchsData] = useState(rawsData);
  const [valuename, setValuename] = useState("");
  const [valueprice, setValueprice] = useState("");
  const [valuesku, setValuesku] = useState("");
  const [toggle, setToggle] = useState(false);
  const [searchButtonDisable, setsearchButtonDisable] = useState(true);
  const decode = tokenDecode();
  const { data } = decode;
  const {
    permission: { product },
  } = data;
  const categoryListing = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/getallproducts`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
        },
      });
      if (data) {
        setIsLoading(false);
        setProductList(data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("");
  const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("");
  const [openInput, setopenInput] = useState(null);
  function maxPriceHandler(id) {
    setopenInput(openInput === id ? null : id);
    const maxPriceProduct = productList.filter((item) => item.id == id);
    setquickUpdatedMaxPrice2(...maxPriceProduct);
    let mp = maxPriceProduct[0].mrp;
    setquickUpdatedMaxPrice(mp);
  }
  async function QuickUpdateHandler(id) {
    let updatedDataToSend;
    let dataForSeller2 = {
      product_id: id,
      price: quickUpdatedMaxPrice,
      status: true,
    };
    if (current_sellerid == 1) {
      updatedDataToSend = {
        ...quickUpdatedMaxPrice2,
        mrp: quickUpdatedMaxPrice,
      };
    } else {
      updatedDataToSend = dataForSeller2;
    }
    const { data } = await axios({
      url: `${apiEndpoint}/updateproductcatalog`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
      },
      data: updatedDataToSend,
    });
    categoryListing();
  }
  const deleteProduct = async (id, reference_id) => {
    try {
      const shopifyProductResponse = await axios({
        method: "POST",
        url: `${apiEndpoint}/product/deleteproductinshopifystore`, // Shopify API endpoint to add product
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Access-Token": "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",
        },
        data: { id: reference_id },
      });
      if (shopifyProductResponse.status === 201) {
        const { data } = await axios({
          url: `${apiEndpoint}/product/deleteproduct`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: { id },
        });
        if (data.status === 200) {
          setProductList(productList.filter((item) => item.id !== id));
          toast.success("Product deleted!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("403 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const inputHandler = async (e) => {
    var optionvalue = e.target.value;
    setValuename("");
    setValueprice("");
    setValuesku("");
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        setToggle(true);
        const { data } = await axios({
          url: `${apiEndpoint}/product/filterproductbyseller`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
          setSproductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setProductList(sproductList);
    }
  };
  const [searchValue, setsearchValue] = useState("");
  const masterSearchHandler = async (e) => {
    let searchedData = e.target.value;
    setsearchValue(searchedData);
    if (searchedData.length > 0) {
      let dataToSend = {
        search: searchedData,
      };
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/searchproduct`,
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: dataToSend,
        });
        if (data.success === true) {
          setProductList(data.data);
        } else {
          console.log("Search API is not running!!");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      if (current_sellerid === 1) {
        categoryListing();
      } else {
        setProductList([]);
      }
    }
  };

  const getProductsUrl = async (ids) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/getProductsUrl`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {productIds:ids},
      });
      if(data?.status === 200){
        // console.log("productUrl",data?.response);
        setProductUrls(data?.response);
      }
      
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const productIds = productList.map(product => product.reference_id);
    if(productIds.length > 0){
      getProductsUrl(productIds);
    }else{
      console.log("No product IDs found.");
    }
  }, [productList]);

  const getUrlByReferenceId = (reference_id) => {
    if (!Array.isArray(productUrls)) {
      return null;
    }
    const urlObject = productUrls.find(urlObj => urlObj.id === reference_id);
    return urlObject ? urlObject.url : null;
  };
  const COLUMNS = [
    {
      Header: "Sn No.",
      Footer: "Sn No.",
      accessor: (row, count) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: (row) => {
        const firstImage =
          Array.isArray(row.image) && row.image.length > 0
            ? row.image[0]
            : null;
        return (
          <img
            src={
              firstImage
                ? firstImage
                : "http://cdn.onlinewebfonts.com/svg/img_546302.png"
            }
            alt="ok"
            height={80}
            width={80}
          />
        );
      },
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Seller",
      Footer: "Seller",
      accessor: "seller_name",
      sticky: "left",
    },
    {
      Header: "Price in USD",
      Footer: "Price in USD",
      accessor: "mrp",
      sticky: "left",
      Cell: ({ value }) => (
        <span className="d-flex justify-content-center align-items-center">
          <IoLogoUsd />{value}
        </span>
      ),
    },
    {
      Header: "Sku",
      Footer: "Sku",
      accessor: "sku",
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (row) => {
        return <>{row.approve_status ? "Active" : "Draft"}</>;
      },
      sticky: "left",
    },
    {
      Header: "Product Url",
      Footer: "Product Url",
      accessor: (row, index) => {
        // console.log(`row__${index}`,row);
        const url = getUrlByReferenceId(row.reference_id);
        return (
          <div
            className="text-primary"
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={() => handleCopyLink(url, index)}
          >
            {copiedRow === index ? (
              <>
                <IoIosCheckmark className="m-1 btn btn-success" style={{height:"1.8em",borderRadius:"100%",width:"1.8em", marginLeft: '10px', padding:"0px" }} />
                <span className="text-success">Copied</span>
              </>
            ) : (
              <>
                <IoIosLink className="m-1 btn btn-primary" style={{height:"1.8em",borderRadius:"100%",width:"1.8em", marginLeft: '10px', padding:"5px" }} />
                <span className="text-primary">Copy Link</span>
              </>
            )}
          </div>
        );
      },
      sticky: "left",
    },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row) => {
        return (
          <>
            {product.update ? (
              <Link
                to={`updatemasterproduct/${row.id}`}
                className="btn-sm m-1 btn btn-success btn-circle"
              >
                <i className="fas fa-pen"></i>
              </Link>
            ) : null}
            {product.delete ? (
              <button
                className="btn-sm m-1 btn btn-danger btn-circle"
                onClick={() => deleteProduct(row.id, row.reference_id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            ) : null}
            <>
              <button class="btn btn-secondary btn-circle d-none" type="button">
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setopenInput(row.id);
                    // console.log("from spab")
                    maxPriceHandler(row.id);
                  }}
                >
                  {" "}
                  QU
                </span>
              </button>
              <>
                {openInput === row.id && (
                  <div style={{ position: "relative" }}>
                    <div class="pop-up">
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "12px",
                          color: "black",
                          paddingTop: "10px",
                        }}
                        className="p-1"
                      >
                        Max Price
                      </p>
                      <div class="pop-up-content">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control form-control-sm w-25"
                            value={quickUpdatedMaxPrice}
                            className="w-50"
                            onChange={(e) => {
                              setquickUpdatedMaxPrice(e.target.value);
                            }}
                          />
                          <button
                            style={{
                              marginLeft: "2px",
                              backgroundColor: "blue",
                              padding: "2px 5px",
                              borderRadius: "3px",
                              border: "none",
                            }}
                            onClick={() => {
                              QuickUpdateHandler(row.id);
                            }}
                          >
                            {" "}
                            Update{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </>
          </>
        );
      },
      sticky: "left",
    },
  ];
  count++;
  useEffect(() => {
    if (current_sellerid === 1) {
      categoryListing();
    }
  }, []);
  function handleShipementModalClose() {
    setshipmentModalOpen(false);
  }
  // function searchHandler(e) {
  //   setsearchValue({ ...searchValue, [e.target.name]: e.target.value });
  //   if (e.target.value == "") {
  //     categoryListing();
  //   }
  // }
  // function selectHandler(e) {
  //   setsearchValue({ ...searchValue, searchFilter: e.target.value });
  //   if (e.target.value !== "") {
  //     setsearchButtonDisable(false);
  //   } else {
  //     setsearchButtonDisable(true);
  //   }
  // }
  // async function searchResult() {
  //   try {
  //     const { data } = await axios({
  //       url: `${apiEndpoint}/searchproduct`,
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
  //       },
  //       data: {
  //         type: searchValue.searchFilter,
  //         name: searchValue.name,
  //       },
  //     });
  //     if (data) {
  //       setProductList(data.data);
  //     }
  //   } catch (error) {}
  // }
  // async function getproductlist() {
  //   let parentId = decoded?.data?.user?.parent_id;
  //   let SellerId = decoded?.data?.user?.seller_id;
  //   let payload;
  //   if (parentId > 1) {
  //     payload = { seller_id: parentId };
  //   } else {
  //     payload = { seller_id: SellerId };
  //   }
  //   setproductisLoading(true);
  //   try {
  //     const { data } = await axios({
  //       url: `${apiEndpoint}/product/listsellerproducts`,
  //       method: "POST",
  //       headers: {
  //         Authorization: `${localStorage.getItem("us_st_d")}`,
  //       },
  //       data: payload,
  //     });
  //     if (data.status === 200) {
  //       setproductisLoading(false);
  //       setProductList(data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  function searchHandler(e) {
    const value = e.target.value.trim();
    setsearchValue(value);  
    if (value === "") {
      categoryListing();
      setNoproducts(false);
    } else {
      searchResult(value);
    }
  }

  async function searchResult(searchText) {
    // if (e.key === "Enter") {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/product/filterProductBySearchValue`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            name: searchText,
            seller_id: current_sellerid,
          },
        });
        if (data) {
          setProductList(data?.response);
          if (data?.data?.length < 1) {
            setNoproducts(true);
          }
        }
      } catch (error) {
        console.log("Error searching for products:", error);
      }
    // }
  }
  function updateProductList(productList) {
    setProductList(productList);
  }

  const exportToExcel = (productlist) => {
    const data = productlist.map((item, index) => {
      return {
        id: item.id,
        seller_id: item.seller_id,
        name: item.name,
        category_id: item.category_id,
        description: item?.description,
        mrp: `$${item?.mrp}`,
        selling_price: `$${item?.selling_price}`,
        tax: item?.tax,
        tags: item?.tags,
        image: item?.image,
        sku: item.sku,
        approve_status: item?.approve_status,
        quantity: item?.quantity,
        brand: item?.brand,
       
      };
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Orders");
    const fileName = `In_Store_Products.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  // function handleSort(e) {
  //   if (e.target.value === 0) {
  //     const sortedLowToHigh = [...productList].sort((a, b) => {
  //       return parseInt(a.tax_included_price) - parseInt(b.tax_included_price);
  //     });
  //     setProductList(sortedLowToHigh);
  //   } else if (e.target.value === 1) {
  //     const sortedHightoLow = [...productList].sort((a, b) => {
  //       return parseInt(b.tax_included_price) - parseInt(a.tax_included_price);
  //     });
  //     setProductList(sortedHightoLow);
  //   } else {
  //     categoryListing();
  //   }
  // }
  // console.log("ProductList",productList);
  function handleSort(e) {
    if (e.target.value == 0) {
      const sortedLowToHigh = [...productList].sort((a, b) => {
        return parseInt(a.tax_included_price) - parseInt(b.tax_included_price);
      });
      setProductList(sortedLowToHigh);
    } else if (e.target.value == 1) {
      const sortedHightoLow = [...productList].sort((a, b) => {
        return parseInt(b.tax_included_price) - parseInt(a.tax_included_price);
      });
      setProductList(sortedHightoLow);
    } else {
      categoryListing();
    }
  }
  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 px-1">
                {/* <h6 className="m-0 font-weight-bold text-primary text-left">
                  Product List
                </h6>
                {current_sellerid == 1 && (
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div
                      className="search mt-2 pl-2 d-none align-items-center"
                      style={{ width: "50%" }}
                    >
                      <div className="row searchDiv  w-75">
                        <select
                          name="searchFilter"
                          id=""
                          className="m-0"
                          onChange={selectHandler}
                          value={searchValue.searchFilter}
                        >
                          <option value="">Search By</option>
                          <option value="Product">Product</option>
                          <option value="Brand">Brand</option>
                          <option value="Category">Category</option>
                        </select>
                        <input
                          type="search"
                          name="name"
                          placeholder="Product, Category or Brand"
                          className="mr-2 rounded-pill m-0"
                          value={searchValue.name}
                          onChange={searchHandler}
                          style={{ fontSize: "14px", width: "75%" }}
                        />
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={searchResult}
                        style={{ marginLeft: "20px" }}
                        disabled={searchButtonDisable}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                )} */}
                <div className="row mx-auto">
                  <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="d-flex flex-column flex-md-row justify-content-between mt-2">
                      <div className="col-md-7 d-flex align-items-center justify-content-between">
                        <input
                          type="search"
                          name="name"
                          placeholder="Search By Product, Category, Price or SKU"
                          className="px-3 py-1 mr-2 rounded-pill inputWidth w-100"
                          value={searchValue}
                          onChange={searchHandler}
                          // onKeyPress={searchResult}
                          // style={{ fontSize: "14px" }}
                        />
                      </div>
                     
                      <div className="col-md-5 d-flex align-items-center justify-content-between mt-2 mt-md-0">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() =>
                            setTimeout(() => {
                              exportToExcel(productList);
                            }, 2000)
                          }
                        >
                          Export Products
                        </button>
                        <Link to={`/${decoded?.data?.user?.seller_id ===1?"createmasterproduct":"createSellerProduct"}`}>
                          <a
                            href="replace"
                            className={`ml-2 d-flex btn btn-sm btn-primary`}
                            // style={{ gap: "20px" }}
                          >
                            <span>{decoded?.data?.user?.seller_id ===1?"Add Product":"Add Product"}</span>
                          </a>
                        </Link>
                        <div className="btn-toolbar p-0 d-flex justify-content-end align-items-center" role="toolbar" style={{ gap: "10px" }}>
                          <p className="d-none">
                            <Applyfilter
                              updateProductList={updateProductList}
                              getproductlist={categoryListing}
                              url="filterinstore"
                            ></Applyfilter>{" "}
                          </p>
                          <select
                            type="button"
                            className="my-2 sortList"
                            style={{
                              background: "lightgrey",
                              marginLeft: "auto",
                              fontSize: "14px",
                              width: "110px",
                              cursor: "pointer",
                              fontWeight: "600",
                              borderRadius: "20px",
                              outline: "none",
                              padding:"7px 6px",
                            }}
                            onChange={handleSort}
                          >
                            <option value="">Sort</option>
                            <option value="0">Low - High</option>
                            <option value="1">High - Low</option>
                          </select>
                        </div>
                       {/* <>
                          {product.update && (
                            <button
                              className="ml-2 btn-sm  btn btn-secondary"
                              onClick={handleSyncModalOpen}
                            >
                              Sync Product
                            </button>
                          )}
                        </>*/}
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="card-body" style={{ minHeight: "70vh" }}>
                {/*<div
                  className="d-flex justify-content-end align-items-center mb-2"
                  style={{ gap: "20px" }}
                >
                  <span
                    className="text-center"
                    style={{
                      background: "lightgrey",
                      borderRadius: "12px",
                      fontSize: "14px",
                      width: "150px",
                      color: "black",
                      fontWeight: "600",
                      height: "27px",
                      cursor: "pointer",
                      paddingTop: "3px",
                    }}
                    onClick={() => {
                      setshipmentModalOpen(true);
                    }}
                  >
                    Import Products
                  </span>
                  </div>*/}
                <div className="table table-responsive ">
                  <div className="productListing_desktop">
                    {!isLoading ? (
                      <Paginated data={productList} columns={COLUMNS} />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "60vh" }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                  <div className="productListing_mobile">
                    {!isLoading ? (
                      <Pagination
                        itemsPerPage={20}
                        items={productList}
                        deleteProduct={deleteProduct}
                        handle="updatemasterproduct"
                        categoryListing={categoryListing}
                      ></Pagination>
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "60vh" }}
                      >
                        <CircularProgress />
                      </div>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <Modal
            open={shipmentModalOpen}
            // onClose={handleShipementModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Sstyle} className="col-md-4 w-100 w-md-25">
              <div className="modal-content ">
                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                  <p
                    className="modal-title genericlabel  "
                    style={{ letterSpacing: "1px" }}
                  >
                    Import Using Excel File..
                  </p>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span style={{ cursor: "pointer" }}>
                      <CloseIcon onClick={handleShipementModalClose} />
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="importingSection">
                    <input
                      type="file"
                      id="inputFile"
                      onChange={(e) => {
                        // console.log("change working");
                        setFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={handleImport}>
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      </ThemeMaker>
    </>
  );
}
