import { useEffect, useState } from "react";
import "./switch.css";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { config } from "../../utils/config";
import Applyfilter from "../../components/Applyfilter/Applyfilter";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Pagination from "../../components/Paginated/Pagination";
import { Paginated } from "../../components/Paginated";
import CircularProgress from "@mui/material/CircularProgress";
import AddCardIcon from '@mui/icons-material/AddCard';
import "../../index.css";
import CloseIcon from "@mui/icons-material/Close";
import ThemeMaker from "../../components/thememaker";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { IoLogoUsd, IoIosLink,IoIosCheckmark } from "react-icons/io";
const { apiEndpoint, baseNodeapiUrl } = config;

const ImageContainer = styled.div`
  max-height: 125px;
  width: 25%;

  img {
    width: 100% !important;
    height: 125px;
    object-fit: contain;
  }
`;

export default function Productcatalog(props) {
  const queryClient = new QueryClient();
  const decoded = tokenDecode();
  let current_sellerid = decoded?.data?.user?.seller_id;
  const {
    permission: { product },
  } = decoded?.data;
  const [searchValue, setsearchValue] = useState("");
  const [openInput, setopenInput] = useState(null);
  const [openQuantyInput, setopenQuantityInput] = useState(null);
  const [openReferenceIdInput, setopenReferenceIdInput] = useState(null);
  const [productlist, setproductlist] = useState([]);
  const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("");
  const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("");
  const [productisLoading, setproductisLoading] = useState(false);
  const [quantiy, setQuantiy] = useState("");
  const [referenceId, setreferenceId] = useState("");
  const [noproducts, setNoproducts] = useState(false);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [syncingData, setsyncingData] = useState({
    seller_id: current_sellerid.toString(),
    price: false,
    inventory: false,
    sku: false,
    title: false,
    weight: false,
  });
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
  const handleSyncModalOpen = () => setOpen(true);

  const [productUrls,setProductUrls] = useState({});
  const [copiedRow, setCopiedRow] = useState(null);

  const handleCopyLink = (url, rowIndex) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopiedRow(rowIndex);
      setTimeout(() => {
        setCopiedRow(null);
      }, 2000); 
    });
  };

  const Sstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
  };
  const handleSyncModalClose = () => {
    setOpen(false);
    setsyncingData({
      seller_id: current_sellerid.toString(),
      price: false,
      inventory: false,
      sku: false,
      title: false,
      weight: false,
    });
  };
  const syncmodalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };
  useEffect(() => {
    getproductlist();
  }, []);
  const categoryListing = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/getallproducts`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decoded?.data?.user?.seller_id,
        },
      });
      if (data) {
        setIsLoading(false);
        setProductList(data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteProduct = async (id, reference_id) => {
    try {
      const shopifyProductResponse = await axios({
        method: "POST",
        url: `${apiEndpoint}/product/deleteproductinshopifystore`, // Shopify API endpoint to add product
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Access-Token": "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",
        },
        data: { id: reference_id },
      });
      if (shopifyProductResponse.status === 201) {
        const { data } = await axios({
          url: `${apiEndpoint}/product/deleteproduct`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: { id },
        });
        if (data.status === 200) {
          setproductlist(productlist.filter((item) => item.id !== id));
          toast.success("Product deleted!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("403 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  async function getproductlist() {
    let parentId = decoded?.data?.user?.parent_id;
    let SellerId = decoded?.data?.user?.seller_id;
    let payload;
    if (parentId > 1) {
      payload = { seller_id: parentId };
    } else {
      payload = { seller_id: SellerId };
    }
    setproductisLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/listsellerproducts`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: payload,
      });
      if (data.status === 200) {
        setproductisLoading(false);
        setproductlist(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  function maxPriceHandler(id) {
    setopenInput(openInput === id ? null : id);
    const maxPriceProduct = productlist.filter((item) => item.id === id);
    setquickUpdatedMaxPrice2(...maxPriceProduct);
    let mp;
    if (maxPriceProduct?.tax_included_price) {
      mp = maxPriceProduct.tax_included_price;
      setquickUpdatedMaxPrice(mp);
    } else if (maxPriceProduct?.mrp) {
      mp = maxPriceProduct.mrp;
      setquickUpdatedMaxPrice(mp);
    }
  }
  async function QuickUpdateHandler(id, mrp) {
    if (parseInt(quickUpdatedMaxPrice) <= parseInt(mrp)) {
      let updatedDataToSend;
      let dataForSeller2 = {
        product_id: id,
        price: quickUpdatedMaxPrice,
        status: true,
      };
      if (current_sellerid === 1) {
        updatedDataToSend = {
          ...quickUpdatedMaxPrice2,
          tax_included_price: quickUpdatedMaxPrice,
        };
      } else {
        updatedDataToSend = dataForSeller2;
      }
      const { data } = await axios({
        url: `${apiEndpoint}/quickeditprice`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: updatedDataToSend,
      });
      if (data.status === 200) {
        setopenInput(null);
        toast.success("Price Updated successfully !", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          getproductlist();
        }, 1000);
      }
    } else {
      alert("Please Enter an amount less then MRP");
    }
  }
  function quantityModalHandler(id) {
    setopenQuantityInput(openQuantyInput === id ? null : id);
  }
  function referenceIdModalHandler(id) {
    setopenReferenceIdInput(openReferenceIdInput === id ? null : id);
  }
  async function quantityUpdateHandler(id) {
    try {
      if (quantiy === "") {
        toast.error("Please Enter Quatity!");
      } else {
        const data = await axios({
          url: `${apiEndpoint}/updatequantity `,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            id: id,
            quantity: quantiy,
          },
        });
        if (data.status === 200) {
          toast.success("Qunatity Updated");
          setopenQuantityInput(null);
          setQuantiy("");
          setTimeout(() => {
            getproductlist();
          }, 1000);
        } else {
          toast.error("Something Went Wrong");
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function ReferenceUpdateHandler(id) {
    try {
      if (referenceId === "") {
        toast.error("Please Enter Reference Id!");
      } else {
        const data = await axios({
          url: `${apiEndpoint}/updateRefId `,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            id: id,
            reference_id: referenceId,
          },
        });
        if (data.data.status === 200) {
          toast.success("ReferenceId Updated");
          setopenReferenceIdInput(null);
          setreferenceId("");
          setTimeout(() => {
            getproductlist();
          }, 1000);
        } else {
          toast.error("Something Went Wrong");
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  function handleSort(e) {
    if (e.target.value == 0) {
      const sortedLowToHigh = [...productlist].sort((a, b) => {
        return parseInt(a.tax_included_price) - parseInt(b.tax_included_price);
      });
      setproductlist(sortedLowToHigh);
    } else if (e.target.value == 1) {
      const sortedHightoLow = [...productlist].sort((a, b) => {
        return parseInt(b.tax_included_price) - parseInt(a.tax_included_price);
      });
      setproductlist(sortedHightoLow);
    } else {
      getproductlist();
    }
  }

  function searchHandler(e) {
    const value = e.target.value.trim();
    setsearchValue(value);  
    if (value === "") {
      getproductlist();
      setNoproducts(false);
    } else {
      searchResult(value);
    }
  }

  async function searchResult(searchText) {
    // if (e.key === "Enter") {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/product/filterProductBySearchValue`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            name: searchText,
            seller_id: current_sellerid,
          },
        });
        if (data) {
          setproductlist(data?.response);
          if (data?.data?.length < 1) {
            setNoproducts(true);
          }
        }
      } catch (error) {
        console.log("Error searching for products:", error);
      }
    // }
  }

  const getProductsUrl = async (ids) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/getProductsUrl`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {productIds:ids},
      });
      if(data.status === 200){
        // console.log("productUrl",data?.response);
        setProductUrls(data?.response);
      }
      
    } catch (error) {
      console.log("error", error);
    }
  };
  // console.log("productlist",productlist);
  useEffect(() => {
    const productIds = productlist.map(product => product.reference_id);
    if(productIds.length > 0) {
      getProductsUrl(productIds);
    }
    // else{
    //   console.log("No product IDs found.");
    // }
  }, [productlist]);

  const getUrlByReferenceId = (reference_id) => {
    if (!Array.isArray(productUrls)) {
      return null;
    }
    const urlObject = productUrls.find(urlObj => urlObj.id === reference_id);
    return urlObject ? urlObject.url : null;
  };

  const COLUMNS = [
    {
      Header: "Sn No.",
      Footer: "Sn No.",
      accessor: (row, count) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: (row) => {
        const firstImage =
          Array.isArray(row.image) && row.image.length > 0
            ? row.image[0]
            : null;
        return (
          <img
            src={
              firstImage
                ? firstImage
                : "http://cdn.onlinewebfonts.com/svg/img_546302.png"
            }
            alt="ok"
            height={80}
            width={80}
          />
        );
      },
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Price in USD",
      Footer: "Price in USD",
      accessor: "mrp",
      sticky: "left",
      Cell: ({ value }) => (
        <span className="d-flex justify-content-center align-items-center">
          <IoLogoUsd />{value}
        </span>
      ),
    },
    {
      Header: "Sku",
      Footer: "Sku",
      accessor: "sku",
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (row) => {
        return <>{row.approve_status ? "Active" : "Draft"}</>;
      },
      sticky: "left",
    },
    {
      Header: "Product Url",
      Footer: "Product Url",
      accessor: (row, index) => {
        // console.log(`row__${index}`,row);
        const url = getUrlByReferenceId(row.reference_id);
        return (
          <div
            className="text-primary"
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={() => handleCopyLink(url, index)}
          >
            {copiedRow === index ? (
              <>
                <IoIosCheckmark className="m-1 btn btn-success" style={{height:"1.8em",borderRadius:"100%",width:"1.8em", marginLeft: '10px', padding:"0px" }} />
                <span className="text-success">Copied</span>
              </>
            ) : (
              <>
                <IoIosLink className="m-1 btn btn-primary" style={{height:"1.8em",borderRadius:"100%",width:"1.8em", marginLeft: '10px', padding:"5px" }} />
                <span className="text-primary">Copy Link</span>
              </>
            )}
          </div>
        );
      },
      sticky: "left",
    },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row) => {
        return (
          <>
            {product.update ? (
              <Link
                to={`updatestoreproduct/${row.id}`}
                className="btn-sm m-1 btn btn-success btn-circle"
              >
                <i className="fas fa-pen"></i>
              </Link>
            ) : null}
            {product.delete ? (
              <button
                className="btn-sm m-1 btn btn-danger btn-circle"
                onClick={() => deleteProduct(row.id, row.reference_id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            ) : null}
            <>
              <button class="btn btn-secondary btn-circle d-none" type="button">
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setopenInput(row.id);
                    // console.log("from spab")
                    maxPriceHandler(row.id);
                  }}
                >
                  {" "}
                  QU
                </span>
              </button>
              <>
                {openInput === row.id && (
                  <div style={{ position: "relative" }}>
                    <div class="pop-up">
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "12px",
                          color: "black",
                          paddingTop: "10px",
                        }}
                        className="p-1"
                      >
                        Max Price
                      </p>
                      <div class="pop-up-content">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control form-control-sm w-25"
                            value={quickUpdatedMaxPrice}
                            className="w-50"
                            onChange={(e) => {
                              setquickUpdatedMaxPrice(e.target.value);
                            }}
                          />
                          <button
                            style={{
                              marginLeft: "2px",
                              backgroundColor: "blue",
                              padding: "2px 5px",
                              borderRadius: "3px",
                              border: "none",
                            }}
                            onClick={() => {
                              QuickUpdateHandler(row.id);
                            }}
                          >
                            {" "}
                            Update{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </>
          </>
        );
      },
      sticky: "left",
    },
  ];

  const [itemCheckboxes, setItemCheckboxes] = useState({});
  useEffect(() => {
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.status;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = productlist.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(productlist.length / 10);
  const handlePageClick = (event) => {
    window.scrollTo(0, 0);
    const newOffset = (event.selected * 10) % productlist.length;
    setItemOffset(newOffset);
  };
  function updateProductList(productList) {
    setproductlist(productList);
  }
  const exportToExcel = (productlist) => {
    const data = productlist.map((item, index) => {
      return {
        id: item.id,
        seller_id: item.seller_id,
        name: item.name,
        category_id: item.category_id,
        description: item?.description,
        mrp: `$${item?.mrp}`,
        selling_price: `$${item?.selling_price}`,
        tax: item?.tax,
        tags: item?.tags,
        image: item?.image,
        sku: item.sku,
        approve_status: item?.approve_status,
        quantity: item?.quantity,
        brand: item?.brand,
       
      };
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Orders");
    const fileName = `In_Store_Products.xlsx`;
    XLSX.writeFile(wb, fileName);
  };
  const handleImport = async () => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        // console.log(data);
        let validData = data.filter((item) => item.name !== null);
        let x = validData.map((item) => {
          return {
            reference_id: item.reference_id,
            position: item?.position,
            selling_price: item?.selling_price,
          };
        });
        sendDataToDb(x);
      },
    });
  };
  async function sendDataToDb(jsonData) {
    try {
      // console.log(jsonData);
      let result = await axios({
        url: `${apiEndpoint}/updateprice`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          // "Content-Type": "multipart/form-data"
        },
        data: jsonData,
      });
      getproductlist();
    } catch (error) {
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  async function syncPriceHandler() {
    try {
      const data = await axios({
        url: `${baseNodeapiUrl}/shopify/sync`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: syncingData.seller_id,
          price: syncingData.price.toString(),
          inventory: syncingData.inventory.toString(),
          sku: syncingData.sku.toString(),
          title: syncingData.title.toString(),
          weight: syncingData.weight.toString(),
        },
      });
      if (data.status === 200 && data.data.status === 200) {
        toast.success("Product Synced Successfully");
      } else {
        toast.error("Product Synced Failed");
      }
    } catch (err) {
      toast.error("Something Went Wrong");
      console.log(err);
    }
  }
  function syncCheckHandler(e) {
    const { name } = e.target;
    // console.log(name);
    // console.log(e.target.value);
    setsyncingData((prev) => {
      return {
        ...prev,
        [name]: !prev[name],
      };
    });
  }
  function handleShipementModalClose() {
    setshipmentModalOpen(false);
  }
  return (
    <ThemeMaker>
      <QueryClientProvider client={queryClient}>
        <div className="row m-0 w-100 mt-1">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header pt-0" style={{ minHeight: "90vh" }}>
                <div className="row mx-auto pt-3 pb-4">
                  <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="d-flex flex-column flex-md-row justify-content-between mt-2">
                    <div className="col-md-7 d-flex align-items-center justify-content-between">
                      <input
                        type="search"
                        name="name"
                        placeholder="Search By Product, Category, Price or SKU"
                        className="px-3 py-1 mr-2 rounded-pill inputWidth"
                        value={searchValue}
                        onChange={searchHandler}
                        // onKeyPress={searchResult}
                        // style={{ fontSize: "14px" }}
                      />
                      </div>
                     {/* <div
                        className="d-flex justify-content-end align-items-center mb-2"
                        style={{ gap: "20px" }}
                      >
                        <span
                          className="text-center"
                          style={{
                            background: "lightgrey",
                            borderRadius: "12px",
                            fontSize: "14px",
                            width: "150px",
                            color: "black",
                            fontWeight: "600",
                            height: "27px",
                            cursor: "pointer",
                            paddingTop: "3px",
                          }}
                          onClick={() => {
                            setshipmentModalOpen(true);
                          }}
                        >
                          Import Products
                        </span>
                        </div>*/}
                      <div className="col-md-5 d-flex align-items-center justify-content-between mt-2 mt-md-0">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() =>
                            setTimeout(() => {
                              exportToExcel(productlist);
                            }, 2000)
                          }
                        >
                          Export Products
                        </button>
                        <Link to={`/${decoded?.data?.user?.seller_id ===1?"createmasterproduct":"createSellerProduct"}`}>
                          <a
                            href="replace"
                            className={`ml-2 d-flex btn btn-sm btn-primary`}
                            // style={{ gap: "20px" }}
                          >
                            <span>{decoded?.data?.user?.seller_id ===1?"Add Product":"Add Product"}</span>
                          </a>
                        </Link>
                        <div
                    className="btn-toolbar p-0 d-flex justify-content-end align-items-center"
                    role="toolbar"
                    style={{ gap: "10px" }}
                  >
                    <p className="d-none">
                      {" "}
                      <Applyfilter
                        updateProductList={updateProductList}
                        getproductlist={getproductlist}
                        url="filterinstore"
                      ></Applyfilter>{" "}
                    </p>
                    <select
                      type="button"
                      className="my-2 sortList"
                      style={{
                        background: "lightgrey",
                        marginLeft: "auto",
                        fontSize: "14px",
                        width: "100px",
                        cursor: "pointer",
                        fontWeight: "600",
                        borderRadius: "20px",
                        outline: "none",
                      }}
                      onChange={handleSort}
                    >
                      <option value="">Sort</option>
                      <option value="0">Low - High</option>
                      <option value="1">High - Low</option>
                    </select>
                  </div>
                       {/* <>
                          {product.update && (
                            <button
                              className="ml-2 btn-sm  btn btn-secondary"
                              onClick={handleSyncModalOpen}
                            >
                              Sync Product
                            </button>
                          )}
                        </>*/}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table table-responsive">
                  <div className="productListing_desktop">
                    {!productisLoading ? (
                      <Paginated data={productlist} columns={COLUMNS} />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "60vh" }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                  <div className="productListing_mobile">
                    {!productisLoading ? (
                      <Pagination
                        itemsPerPage={20}
                        items={productlist}
                        deleteProduct={deleteProduct}
                        categoryListing={categoryListing}
                      ></Pagination>
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "60vh" }}
                      >
                        <CircularProgress />
                      </div>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <Modal
            open={shipmentModalOpen}
            // onClose={handleShipementModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Sstyle} className="col-md-4 w-100 w-md-25">
              <div className="modal-content ">
                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                  <p
                    className="modal-title genericlabel  "
                    style={{ letterSpacing: "1px" }}
                  >
                    Import Using Excel File..
                  </p>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span style={{ cursor: "pointer" }}>
                      <CloseIcon onClick={handleShipementModalClose} />
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="importingSection">
                    <input
                      type="file"
                      id="inputFile"
                      onChange={(e) => {
                        // console.log("change working");
                        setFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={handleImport}>
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      </QueryClientProvider>
    </ThemeMaker>
  );
}
