import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import { Paginated } from "../../components/Paginated";
import Sellerpagination from "../../components/Paginated/Sellerpagination";
import styles from "./sellerList.module.css";
import "../../components/table.css";
import CircularProgress from "@mui/material/CircularProgress";

const { apiEndpoint } = config;

const A = () => <span className="badge badge-primary">New Request</span>;
const B = () => <span className="badge badge-success">Approved</span>;
const C = () => <span className="badge badge-danger">Blocked</span>;
const valueToApp: any = {
  "0": <A />,
  "1": <B />,
  "2": <C />,
};
var count = 1;

var $ = require("jquery");

export default function SellerList() {
  const decoded: any = tokenDecode();
  const { data } = decoded;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;
  const [sellerList, setSellerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSellerList, setIsSellerList] = useState(false);
  const fetchSellerList = async () => {
    setIsLoading(true);
    setIsSellerList(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/getSellerList`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        setIsLoading(false);
        setIsSellerList(false);
        setSellerList(data.data);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setIsSellerList(false);
    }
  };
  const inputHandler = async (e: any) => {
    setIsLoading(true)
    try {
      var optionvalue = e.target.value;
      console.log(optionvalue);
      if (optionvalue === "pendingseller") {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/getpendingsellerlist`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
        });
        console.log("pending data", data.data);
        if (data.status === 200) {
          setSellerList(data.data);
          setIsLoading(false);
        }
      } else if (optionvalue === "approveseller") {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/getapprovedsellerlist`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
        });
        console.log("pending data", data.data);
        if (data.status === 200) {
          setIsLoading(false);
          setSellerList(data.data);
        }
      } else {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/getsellerlist`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
        });
        if (data.status === 200) {
          setIsLoading(false);
          setSellerList(data.data);
        }
      }
    } catch (error) {
      console.log("error", error);

      setIsLoading(false);
    }
  };

  const sellerDelete = async (id: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/sellerdelete`,
        method: "DELETE",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: id,
        },
      });
      const updateSellerList = sellerList.filter((item: any) => item.id !== id);
      if (data.status === 200) {
        toast.success("Seller Removed Successfully !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      window.location.reload();
    } catch (error) {
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const COLUMNS = [
    {
      Header: "Sn No.",
      Footer: "Sn No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Company Name",
      Footer: "Company Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Email",
      Footer: "Email",
      accessor: "email",
      sticky: "left",
    },
    {
      Header: "Contact",
      Footer: "Contact",
      accessor: "contact_no",
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (row: any) => {
        return <>{valueToApp[row.approve_status]}</>;
      },
      sticky: "left",
    },
    {
      Header: "Action",
      Footer: "Action",
      accessor: (row: any) => {
        return (
          <>
            {seller.update ? (
              <Link
                to={`../../updateseller/${row.seller_id}`}
                className="btn btn-success btn-circle"
              >
                {" "}
                <i className="fas fa-eye"></i>
              </Link>
            ) : null}
            {seller.delete ? (
              <button
                className="btn btn-danger btn-circle d-none"
                onClick={() => sellerDelete(row.id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            ) : null}
          </>
        );
      },
      sticky: "left",
    },
  ];
  count++;

  useEffect(() => {
    fetchSellerList();
  }, []);

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary text-left">
                  Seller list
                </h6>
                <div className={styles.sellerListOptions}>
                  {" "}
                  <b>Filter By: </b>
                  <select
                    name="status"
                    id=""
                    onChange={(e: any) => inputHandler(e)}
                  >
                    <option value="allseller" selected>
                      All
                    </option>
                    <option value="pendingseller">Pending Seller</option>
                    <option value="approveseller">Approved Seller</option>
                  </select>{" "}
                </div>
              </div>
              {!isLoading ? (
                <div className={`card-body p-0 ${styles.cardbody}`}>
                <div className="table table-responsive ">
                  <div className={styles.sellerListing_desktop}>
                    <Paginated data={sellerList} columns={COLUMNS} />
                  </div>
                  <div className={styles.sellerListing_mobile}>
                    <Sellerpagination
                      itemsPerPage={20}
                      items={sellerList}
                      sellerDelete={sellerDelete}
                      paginationFor="seller"
                    ></Sellerpagination>
                  </div>
                </div>
              </div>
              ) : (
                  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }} >
                    <CircularProgress />
                  </div>
              )}
              
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
