import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { config } from "../../utils/config";
import { Paginated } from "../../components/Paginated";
import "../../components/table.css";
import CircularProgress from "@mui/material/CircularProgress";
import Sellerpagination from "../../components/Paginated/Sellerpagination";

const { apiEndpoint } = config;

var $ = require("jquery");

var count = 1;

export default function UserList() {
  const [userlist, setUserList] = useState([]);
  const [userloading, setuserLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellerList, setSellerList] = useState([]);
  const rawData = {
    seller_id: "",
  };
  const [searchData, setSearchData]: any = useState(rawData);
  const decode = tokenDecode();
  const { data } = decode;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;
  const fetchUserList = async () => {
    let parentId = decode?.data?.user?.parent_id;
    let SellerId = decode?.data?.user?.seller_id;
    let payload;
    if (parentId > 1) {
      payload = { seller_id: parentId };
    } else {
      payload = { seller_id: SellerId };
    }
    try {
      setuserLoading(true);
      const { data } = await axios({
        url: `${apiEndpoint}/user/getuserlist`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: payload,
      });
      // console.log("user listing", data);
      setuserLoading(false);
      if (data.status === 200) {
        setUserList(data.data);
      }
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 500);
    } catch (error) {
      setuserLoading(false);
    }
  };
  const userDelete = async (id: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/user/userdelete`,
        method: "DELETE",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      // console.log("data----", data);
      if (data.status === 200) {
        setUserList(userlist.filter((item: any) => item.id !== id));
        fetchUserList();
        toast.success("User deleted !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 Error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const getSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/getsellerlist`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        // console.log(data.data)
        setSellerList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const inputHandler = async (e: any) => {
    try {
      var optionvalue = e.target.value;
      setSearchData({ ...searchData, [e.target.name]: e.target.value });
      // console.log(optionvalue);
      const { data } = await axios({
        url: `${apiEndpoint}/getuserfilterbyseller`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: optionvalue,
        },
      });

      if (data.status === 200) {
        // console.log(data);
        setUserList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const COLUMNS = [
    {
      Header: "Serial No.",
      Footer: "Serial No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Email",
      Footer: "Email",
      accessor: "email",
      sticky: "left",
    },
    {
      Header: "Contact",
      Footer: "Contact",
      accessor: "contact_no",
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (row: any) => {
        return (
          <>
            {row.status ? (
              <span className="badge badge-success">Active</span>
            ) : (
              <span className="badge badge-danger">Deactive</span>
            )}
          </>
        );
      },
      sticky: "left",
    },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row: any) => {
        return (
          <>
            {user.update ? (
              <Link
                to={`../../updateuser/${row._id}`}
                className="btn btn-success btn-circle"
              >
                {" "}
                <i className="fas fa-eye"></i>
              </Link>
            ) : null}
            {user.delete ? (
              <button
                className="btn btn-danger btn-circle"
                onClick={() => userDelete(row._id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            ) : null}
          </>
        );
      },
      sticky: "left",
    },
  ];
  count++;
  useEffect(() => {
    // $('#dataTable').DataTable();
    fetchUserList();
    getSellerList();
  }, []);
  const [searchValue, setsearchValue] = useState("");
  const masterSearchHandler = async (e: any) => {
    let searchedData = e.target.value;
    setsearchValue(searchedData);

    let dataToSend = {
      title: searchedData,
    };
    // console.log(dataToSend)
    try {
      const { data } = await axios({
        url: "https://shikahrapp.hulsecure.in/public/index.php/api/searchUserFilter",
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: dataToSend,
      });
      // console.log("Response from Server-------------", data)
      if (data.status == 200) {
        setUserList(data.message);
      } else {
        // console.log("Search API is not running!!");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h6 className="m-0 font-weight-bold text-primary text-left">
                  User list
                </h6>

                <div className="search ">
                  <input
                    type="search"
                    name="name"
                    placeholder="Search User"
                    className="mr-2 w-100 form-control"
                    value={searchValue}
                    onChange={masterSearchHandler}
                  />
                </div>
              </div>
              <div className="card-body p-0 p-1" style={{ minHeight: "70vh" }}>
                <div className={`table table-responsive ${userlist.length > 0 ? 'table-bordered' : ''}`}>
                  <div className="productListing_desktop">
                    {!userloading ? (
                      <>
                      {userlist.length > 0 ? (
                        <Paginated data={userlist} columns={COLUMNS} />
                      ) : (
                        <div
                          className={`d-flex align-items-center justify-content-center text-dark`}
                        >
                          No User found
                        </div>
                      )}
                      </>
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "60vh" }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                  <div
                    className="productListing_mobile"
                    style={{ padding: "10px" }}
                  >
                    {!userloading ? (
                    <Sellerpagination
                      itemsPerPage={20}
                      items={userlist}
                      sellerDelete={userDelete}
                      paginationFor="user"
                    ></Sellerpagination>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "60vh" }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
